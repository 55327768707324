@import "../variables";

.templates-content {

  .templates-title {
    flex-grow: 1; }

  .to-templates-button {
    .icon {
      margin: 0 !important; } } }


.no-data {
  margin-left: 5px; }

.user-templates {
  display: inline-flex;
  flex-wrap: wrap;
  margin-right: 5px !important;
  margin-left: 5px !important;
  width: 100%;

  margin-bottom: $bottom-menu-height / 2;

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 6!important;
    -webkit-box-orient: vertical; }

  .content {
    overflow: hidden;
    text-overflow: ellipsis; }

  .templates-content {
    display: flex; }

  h1 {
    font-size: 20px;
    color: #252529;
    margin-bottom: 16px; }

  > .card {
    min-height: 190px;
    margin-bottom: 16px;
    width: calc(33% - 10px);
    position: relative;
    margin-right: 10px;
    float: left;
    justify-content: space-around;
    max-width: 100%;
    @media screen and (max-width: 1400px) {
      width: 30%; }
    @media screen and (max-width: 950px) {
      width: 40%; }
    @media screen and (max-width: 650px) {
      width: 100%; }

    .favorite-icon {
      position: relative;
      top: -1px;
      margin-right: 5px; }

    > .card-content {
      font-size: 14px;
      padding: 16px;
      cursor: pointer;
      .is-offline-tag {
        font-size: 10px;
        font-weight: 500;
        line-height: 14px;
        text-align: center; }
      .edit-template-icon {
        position: absolute;
        top: -2px;
        right: 6px;
        font-size: 18px;
        font-size: 18px;

        .icon {
          width: 1rem;
          height: 1rem; } }

      .columns .column {
        > h2 {
          font-size: 16px;
          color: #000000;
          margin-bottom: 5px; } }

      > div > .content {
        margin-top: 16px;
        margin-bottom: 14px;
        img {
          width: unset;
          max-width: 100%; } }

      .project {
        position: relative;
        .link-icon {
          float: left;
          path {
            fill: $grey; } }
        .project-title {
          font-size: 14px;
          display: inline-block;
          margin-left: 8px;
          padding-top: 3px;
          max-width: calc(100% - 70px); } } } } }

