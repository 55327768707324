.overflowHidden {
    -webkit-overflow-scrolling: touch !important;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    height: 100vh;
}

.slider {
    position: fixed;
    display: flex;
    bottom: 1rem;
    right: 10px;
    z-index: 200;
    border-radius: 20px;
    height: auto;
    padding: 1.6rem;
    text-align: center;
    vertical-align: middle;
}

.slider-wrap {
    position: relative;
    width: 20px;
    display: flex;
    background-color: rgba(125, 125, 125, 0.8);
    padding: 10px 0;
    border-radius: 20px;
    height: auto;
}

.range {
    display: flex;
    position: relative;
    width: 20px;
    z-index: 9;
}

.range input {
    background: none !important;
    border: none !important;
    flex: 1;
    left: 36%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%) rotate(90deg);
    -webkit-appearance: none;
    z-index: 999999;
}

.range input::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    margin: -8px 0 0;
    border-radius: 50%;
    background: #3074dd;
    cursor: pointer;
    border: 2px !important;

    background-position: 6px;
    background-repeat: no-repeat;
    background-size: 7px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.range input::-moz-range-thumb {
    width: 20px;
    height: 20px;
    margin: -8px 0 0;
    border-radius: 50%;
    background: #3074dd;
    cursor: pointer;
    border: 2px !important;

    background-position: 6px;
    background-repeat: no-repeat;
    background-size: 7px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.range input::-ms-thumb {
    width: 20px;
    height: 20px;
    margin: -8px 0 0;
    border-radius: 50%;
    background: #3074dd;
    cursor: pointer;
    border: 2px !important;

    background-position: 6px;
    background-repeat: no-repeat;
    background-size: 7px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.range input::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: none;
    background-color: none;
}

.range input::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: none;
}

.range input::-ms-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: none;
}

.range input:focus {
    background: none;
    outline: none;
    background-color: #fff;
}

.range input::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

.range-labels {
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style: none;
    position: relative;
    flex: 1;
}

.range-labels a {
    display: block;
    position: relative;
    text-align: center;
    color: transparent;
    font-size: 14px;
    cursor: pointer;
    height: 30px;
    padding: 1px 0;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    z-index: 9;
}

.range-labels a::before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    margin: 0 auto;
    width: 9px;
    height: 9px;
    background: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

.range-labels a.prior::before {
    background: red;
}

.range-labels .active, .range-labels .focus {
    color: transparent;
    z-index: -1;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.range-labels .selected::before {
    background: #37adbf;
}

.range-labels .active.selected::before {
    display: none;
}

.slider-wrap #drag-handle {
    position: absolute;
    right: auto;
    top: calc(0.7rem);
    padding: 0 20px;
    z-index: 999999;
    display: block;
    border-radius: 50%;
    min-width: 1rem;
    margin-left: -1.5rem
}

.slider-wrap .icon.is-medium {
    font-size: 1.2rem;
    padding: 0.7rem;
    position: relative;
    right: 0.1rem;
    top: 0.15rem;
    display: flex;
    line-height: 0.5rem;
}

i.fa.fa-arrows-alt-v {
    background: #3074dd;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    z-index: 99999;
    font-size: 1.0rem;
    padding: 0.7rem;
    display: inline-block;
    line-height: 0.5rem;
    box-shadow: 0 3px 6px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
}

.float {
    padding: 3px 7px;
    position: fixed;
    right: 3%;
    bottom: 34px;
}

.arrow {
    width: auto;
    position: fixed;
    display: flex;
    bottom: 30px;
    right: 30px;
    padding: 1rem;
    z-index: 50
}

.arrow span {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999999;
    display: block;
    height: 1rem;
    display: inline-block;
    width: 1rem;
    border-radius: 50%;
    min-width: 1rem;
}
