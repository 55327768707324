
.import-tab {
    margin-top: 0 !important;
    .import-tab-content {
        min-height: 30vh;
        .import-result {
            .table {
                tr.is-warning {
                    background-color: $warning; }
                tr.is-danger {
                    background-color: $danger;
                    color: $white; } } } }

    .import-circle {
        margin: 10px auto 0; } }
