@import "../_variables.sass";

.react-tabs__tab-list {
  border-bottom: none !important;
  font-weight: 600;
  color: #959BBE;
  font-size: 16px;
  line-height: 24px; }

.react-tabs__tab {
  border: none !important;
  border-radius: unset !important;
  font-size: 16px !important;
  line-height: 24px;
  color: #959BBE; }
@media screen and (max-width: $mobile) {
  .react-tabs__tab {
    padding: 6px 6px !important; } }

.react-tabs__tab--selected {
  color: $alphaprocess-blue;
  background: $background-color !important; }


