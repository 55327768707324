@import "../_variables.sass";
.folder {
  .data-table {
    .resource-tag {
      max-width: 100%;
      width: unset;
      overflow: hidden;
      display: inline-block;
      padding-top: 2px;
      text-overflow: ellipsis; }
    .image {
      flex-shrink: 0; }

    .icon {
      font-size: 16px; }

    .folder-icon {
      margin-right: 6px;
      width: 24px; }

    .offline-icon {
      margin-left: 4px;
      i {
        color: $alphaprocess-orange; } } }

  .folder-navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    table {
      .is-flex {
        align-items: center; } }

    .breadcrumb {
      flex-grow: 1;
      margin-left: 5px;
      padding-top: 4px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: bold;

      li {
        display: block;
        margin-left: 4px;
        max-width: 200px;

        &:before {
          margin-right: 4px; }

        &:not(.is-active) {
          cursor: pointer; } } } }

  .react-pencil {
    input {
      font-size: 16px;
      font-weight: 600; }

    .pencil-button {
      display: none; } }

  .is-mobile {
    .create-folder-button {
      margin-right: 9px; } }
  .is-not-mobile {
    .create-folder-button {
      margin-right: 10px;
      margin-bottom: 10px;
      width: 169px;

      .icon {
        margin-right: 0 !important;

        .fas {
          margin-right: 2px; } } } } }

.folder-mobile {
  margin-bottom: 100px;
  .data-table {
    td {
      vertical-align: top; }
    .checklist-progress {
      width: 100%;
      .progress {
        width: 65%; } } } }

