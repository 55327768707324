@import "../_variables.sass";

$forbidden-opacity: 0.5;

.tts-modal {
  .modal-content {
    overflow: hidden; } }

@mixin is-highlighted {
  border: 1px solid $red;
  transition: border 500ms; }

.result-view-card {
  .notification {
      margin-left: 1px;
      margin-right: 1px; } }

.safe-area-block {
  z-index: 100;
  background: $background-color;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  height: constant(safe-area-inset-top);
  height: env(safe-area-inset-top); }

.checklist-overview {
  margin-right: 3px; }

.checklist-progress {
  position: relative;
  top: 5px;
  .icon {
    margin-left: 4px; }
  .fa-info-circle {
    padding-top: 4px; } }


#history-quickview {
  z-index: $layer6; }

.edit-result-set-form {
  .react-datepicker-popper {
    z-index: $layer6;
    transform: translate(7px, 67px) !important;
    left: 0px!important;
    right:0px!important {} } }

@media screen and (max-width: $tablet) {
  .back-button-container {
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top); }

  #result-column {
    width: calc(100% - 1px);
    position: absolute; } }

.overview-content {
  padding-left: 0;
  padding-bottom: 0;
  @media screen and (min-width: $tablet) {
    padding-top: 0; } }

.back-button-container {
  padding: 15px 20px 15px;
  // sticky breaks the modal filepond display in Safari...
  //position: sticky !important
  //position: -webkit-sticky !important
  top: 0px;
  top: constant(safe-area-inset-top);
  top: env(safe-area-inset-top);
  display: block;
  z-index: $layer2;
  background-color: white;
  &.mobile {
    background-color: $background-color; } }

.error-result-modal {
  .not-allowed-link {
    color: $text; }

  .area-permission-notification {
    p:last-child {
      margin-top: 1em !important; } } }

#result-column {
  height: 100%;
  padding: 0;
  background: $white;
  @media screen and (max-width: $tablet) {
    padding: 0;
    margin-top: 0;
    background: transparent; }

  @media screen and (min-width: $tablet) {
    overflow: auto;
    max-height: calc(100vh - 117px);

    border: 1px solid rgba(212, 216, 224, 1);
    border-radius: $border-radius $border-radius 0 0; } }

.date-input {
  background: $white;
  height: unset; }

.result-view-card {
  //position: fixed
  padding: 8px 0px;
  min-height: 100px;
  min-height: calc(100px + #{constant(safe-area-inset-top)});
  min-height: calc(100px + #{env(safe-area-inset-top)});
  @media screen and (max-width: $tablet) {
    background-color: $background-color;
    padding: 5px 0 0;
    width: 100%;
    box-shadow: none !important; }

  .modal-card {
    ul {
      list-style-type: none;
      margin-left: 0px !important; }
    a {
      border-radius: 5px;
      transition: 0.1s ease-in-out;
      padding: 2px; }
    a:hover {
      background-color: $link-hover;
      color: white; }
    .error-modal-item {
      padding: 5px;
      transition: 0.1s ease-in-out; }
    .error-modal-item:hover {
        background-color: #FAFAFA;
        border-radius: 5px; } }


  .start-date-control {
    width: calc(100% - 12px);

    .start-date-input {
      height: 36px;
      color: #363636; }

    .button {
      font-weight: 700;

      .icon {
        margin-left: 0;
        margin-right: 0; } }

    .start-date-buttons {
      width: 50%; }

    .text-control {
      margin-right: 1rem; }

    .control {
      width: 50%; } } }

.result-head {
  @media screen and (min-width: $tablet) {
    padding-top: 5px;
    position: sticky;
    top: 48px;
    z-index: $layer2; }
  display: block;
  overflow: unset;
  background-color: white;
  @media screen and (max-width: $tablet) {
    background-color: $background-color; } }

.result-head-direct {
  @media screen and (min-width: $tablet) {
    top: 0; } }

.image-column {
  flex-grow: 0; }

.close-result {
  width: 16px;
  height: 16px;
  margin: 6px 4px 4px 10px;
  cursor: pointer; }

.area-card-columns {
  padding-bottom: 60px;
  .columns {
    flex-direction: column; } }

.area-card {
  min-height: 145px !important;
  height: 145px;
  max-height: 145px;

  // X tasks open
  // if the screen is too small reduce the font size
  // to prevent a line break (which would cause a wrong positionised progress bar)
  @media screen and (max-width: 430px) {
    .tasks-open {
      font-size: 12px; } }

  &.menu-is-open {
    z-index: 20; }

  &-forbidden {
    opacity: $forbidden-opacity; }



  .dropdown-content {
    padding-bottom: unset;
    padding-top: unset; }

  .dropdown-menu {
    width: 250px; }

  .dropdown-item .icon {
    margin-right: 0 !important; }

  .has-text-grey {
    color: $mobile-light-grey;
    margin-top: 3px;
    margin-bottom: 3px; }

  .card-content {
    padding: 0.8rem; } }

.dropdown-menu {
  right: -10px !important;
  width: 140px; }

.area-dropdownmenu {
  right: 0;
  left: unset;
  min-width: 280px !important;
  text-align: left;

  a {
    font-weight: bold; }

  .report-downloader {
    padding-left: 10px;
    font-weight: bold;
    display: block;
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

.mini-accordion {
  .mini-accordion-title {
    font-size: 14px;
    margin-bottom: 16px;

    .tag {
      margin-left: 4px;
      padding: 7px; } } }

.error-modal-item {
  h4 {
    margin-top: 1rem; } }

.area-permissions-modal {
  z-index: $layer7 !important;

  .list-entry {
    margin-bottom: 23px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0; } }

  .notification {
    margin-left: 0;
    margin-right: 0; }

  .entry-text {
    margin-top: 18px !important;

    h5 {
      font-size: 1.125em; } }


  .switch-container {
    padding: 36px 0 0 0;

    label {
      position: absolute !important;
      right: 0;
      top: 18px; } } }

.has-red-border {
  border: 1px solid $red; }

// ADF-335: Show a red border around questions that are required and not filled
// after trying to close a checklist
.has-invisible-border {
  border: 1px solid transparent; }

.overview-content {
  padding-right: 0; }

.audit-form {
  padding-bottom: 10px;
  @media screen and (min-width: $tablet) {
    margin-top: 24px; }

  .result-head .columns, .sticky-progressbar, .result-question, .has-audit-form-padding {
    padding: $audit-form-padding !important; }

  .result-set-head {
    .offline-available {
      color: $alphaprocess-orange !important; }
    .description {
      width: 100%;
      //max-height: 118px
      text-overflow: ellipsis;
      overflow: hidden; }

    .is-ellipsed {
      max-width: 85%; }

    .result-cloud-attachments {
      margin-bottom: 1.5em;
      h3 {
        font-size: 20px; } } }

  .caption {
    color: #828894;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 24px; }

  .second-caption {
    color: #252529;
    font-size: 20px;
    font-weight: bold;
    line-height: 32px; }

  .third-caption {
    color: $mobile-light-grey;
    font-size: 14px;
    line-height: 24px; }

  .sticky-button {
    display: block;
    right: 0;
    bottom: 0;
    @media screen and (min-width: $tablet) {
      bottom: 0;
      position: sticky;
      background-color: transparent; }
    background-color: $background-color;
    position: fixed;
    z-index: $layer2;
    width: 100%;
    margin: 0 1% 0 0;
    padding: 20px 0;

    #close-result-button, #start-next-result-button {
      @media screen and (min-width: $tablet) {
        width: 100%;
        margin: 0; }
      width: 80%;
      margin-right: 10%;
      margin-left: 10%;
      height: 50px;
      z-index: $layer5; }
    #start-next-result-button {
      margin-top: 8px; } }

  .dropdown.cloud-file-selector {
    width: 100%;
    .dropdown-menu {
      width: 50%; }

    .dropdown-trigger {
      width: 50%;
      .button {
        width: 100%; } } }

  .dropdown-menu:not(.cloud-file-selector) {
    .dropdown-item {
      white-space: normal;
      padding-left: 5px;
      text-align: left;
      font-size: $dropdown-item-font-size !important;
      line-height: $dropdown-item-line-height;
      font-weight: $dropdown-item-font-weight;
      margin-bottom: $dropdown-item-margin-bottom;

      .icon {
        margin-right: $dropdown-item-icon-margin-right; } } }

  .audit-tag {
    border-radius: 14px;
    padding: 9px 7.5px;
    margin-right: 16px;
    font-size: 12px; }

  .icon-settings {
    width: 24px;
    margin-top: 2px; }

  .icon-add {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: #d6e9fa;
    border-radius: 28px;
    border: 1px solid #d6e9fa;
    text-align: center;

    img {
      width: 13px; } }

  .question {
    position: relative;
    margin-bottom: 25px; }

  .seperator {
    position: absolute;
    bottom: 0; }

  @media screen and (max-width: $mobile) {
    .progress {
      border-radius: 4px;
      top: 22px !important; }
    .sticky-progressbar {
      &.is-hidden-desktop {
        background-color: $background-color !important; } } }

  .progress {
    border-radius: 4px;
    top: 16px; }

  .sticky-progressbar {
    position: sticky;
    background-color: transparent;
    top: 0;
    top: constant(safe-area-inset-top);
    top: env(safe-area-inset-top);
    z-index: $layer2;
    height: 40px;
    border-bottom: 1px solid $separator-color;
    margin-bottom: 8px;

    .progress {
      @media screen and (min-width: $tablet) {
        height: 8px !important; } } }

  .question-title {
    .label {
      color: $black;
      font-family: "SF Pro Text";
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      cursor: pointer !important; } }

  .task-details-label {
    color: $black;
    font-family: "SF Pro Text";
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 7px;
    pointer: cursor; }

  .task-details-text {
    color: $black;
    font-family: "SF Pro Text";
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px; }

  .progress-container {
    font-size: 14px;
    margin-bottom: 8px;
    flex-direction: row !important; }

  .is-resource-tag, .is-contact-tag, .is-company-tag {
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px; }

  .is-due-tag {
    color: #AF7D00;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    background-color: #FFF5DB; }

  .is-project-tag {
    color: white;
    background-color: $link;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    width: 90px; }

  .is-project-tag, .is-status-tag, .created-at-tag {
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: center; }

  .is-ellipsed-tag {
    max-width: 100px; }

  .audit-panel {
    box-shadow: none; }

  .headline-padding {
    padding: 0 1.25rem; }

  .dropdown-menu {
    z-index: $layer7; }

  .dropdown-content {
    a {
      text-decoration: none !important;
      padding: 0px; } } }

.dropdown-question-item {
  margin-bottom: 10px;

  &.has-additional-text {
    .input-container {
      margin-top: 5px; } }

  .additional-text {
    margin-left: 5px;
    border-bottom: 1px solid $input-border-color;
    padding: 0 5px;
    font-size: inherit; }

  label {
    margin-bottom: 0 !important; }

  li {
    display: block; }

  input {
    margin-right: 5px; }

  input[type="checkbox"],
  input[type="radio"] {
    display: none; }

  input[type="checkbox"] + label::before {
    border-radius: 5px; }

  input[type="radio"] + label::before {
    border-radius: 15px; }

  input[type="checkbox"] + label::before,
  input[type="radio"] + label::before {
    width: 15px;
    height: 15px;
    border: 2px solid $info;
    background-color: #fff;
    display: block;
    content: "";
    float: left;
    margin-right: 5px;
    margin-top: 5px; }

  input[type="checkbox"].has-no-value + label::before,
  input[type="radio"].has-no-value + label::before {
    border: 2px solid $grey; }

  input[type="checkbox"]:checked + label::before,
  input[type="radio"]:checked + label::before {
    box-shadow: inset 0px 0px 0px 3px #fff;
    background-color: $info; } }

@media screen and (min-width: $tablet) {
  .is-horizontal {
    display: inline-block;
    margin-right: 5px; } }

.result-question {
  .datetime-question.is-highlighted {
    input:first-child {
      @include is-highlighted; } }

  .text-input.is-highlighted {
    input:first-child, textarea {
      @include is-highlighted; } }

  .number-input.is-highlighted {
    @include is-highlighted; }

  @media screen and (min-width: $tablet) {
    .number-input {
      max-width: 33%; } }

  @media screen and (max-width: $mobile) {
    .number-input {
      max-width: 50%; } }

  .result-content-block, .result-cloud-attachments {
    margin-top: 40px;
    .cloud-file-list {
      margin-top: 16px; } }

  .attachment-buttons {
    .dropdown {
      border: none !important;
      .dropdown-trigger, .button {
        width: 100% !important; } }
    .button, .dropdown {
      border: 1px solid #D4D8E0;
      margin-right: 0 !important;
      width: 50% !important;

      .icon {
        > i {
          margin-top: 2px; } }

      span.is-required {
        position: relative;
        top: -1px;

        &:after {
          margin-left: 2px;
          content: "*";
          color: $red;
          font-weight: bold;
          font-size: 1.3em; } } } }

  .result-question-block {
    margin-top: 5px;
    position: relative;

    .question-rating.is-highlighted {
      .button {
        @include is-highlighted; } }

    .buttons.is-highlighted {
      .button {
        @include is-highlighted; } }

    .buttons-with-na {
      .button {
        border: 1px solid #D4D8E0;
        margin-right: 0 !important;
        width: 33.33%;
        height: 44px;
        border-radius: 4px 0 0 4px; } }

    .buttons-without-na {
      .button {
        border: 1px solid #D4D8E0;
        margin-right: 0 !important;
        width: 50% !important;
        height: 44px;
        border-radius: 4px 0 0 4px; } }

    .button-half {
      width: 50%; } }

  .result-comment {
    margin: 0.5rem 0;
    white-space: pre-line;

    &.is-highlighted {
      @include is-highlighted; } }

  .attachment-modal {
    .modal-content {
      overflow: hidden !important; } }

  .image-editor-wrapper {
      &.desktop {
        width: 80%;
        height: calc(100vh - 64px);
        padding: 10px;

        .tui-image-editor {
          top: 0 !important; } }

      &.mobile {
        width: 100%;
        height: 100vh;
        padding-top: 0;
        padding-bottom: 0;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
        padding-bottom: constant(safe-area-inset-top);
        padding-bottom: env(safe-area-inset-top);
        max-height: calc(100vh);

        .is-not-rounded {
          border-radius: 0; } }

      .tui-image-editor-header {
          display: none; }
      // Workaround for some conflicts with our css
      .tui-colorpicker-palette-preview {
        color: transparent !important; }
      .tui-colorpicker-palette-container {

        .tui-colorpicker-palette-hex {
          margin-top: 0 !important; }

        .tui-colorpicker-clearfix {
          margin: 0 2px 0px 0px !important; }

        ul.tui-colorpicker-clearfix {
          display: inline-block;
          li {
            float: left; } } }
      .tui-colorpicker-palette-button {
        height: 16px;
        width: 16px; }

      .tui-image-editor-main {
        top: 0 !important; } }

  .attachments-container {
    margin-right: 2em;

    &.is-highlighted {
      @include is-highlighted; }

    .attachment-thumb, .image-column {
      position: relative;
      min-height: 128px;

      @extend .has-margin-top-5;

      figure.image {
        margin: auto;
        text-align: center;
        max-width: 100%;
        img {
          margin: auto;
          height: 100%;
          width: auto;
          object-fit: contain; } }

      .filename {
        text-align: center;
        font-size: 0.8em;
        word-break: break-word;
        padding: 0 20px; }

      .delete-icon {
        position: absolute;
        top: 0;
        right: 0;
        z-index: $layer1; }

      .edit-icon {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $layer1; } } }

  @media screen and (min-width: $desktop) {
    .delete-icon, .edit-icon {
      font-size: 18px;
      opacity: 0;
      transition: opacity .25s ease-in-out; }

    .attachment-thumb:hover,
    .image-column:hover {
      .delete-icon, .edit-icon {
        opacity: 1;
        transition: opacity .25s ease-in-out; } } } }

.attachments-container:not(.cloud) {
  .attachment-thumb {
    a, div.icon-wrapper {
      display: block;
      text-align: center;
      text-decoration: none !important;
      top: 0;
      right: 0;
      left: 0;

      .icon {
        font-size: 50px;
        width: unset;
        height: unset;
        display: block; } } } }


// ADF-244: option to display single or multiselect options horizontal instead of vertical
// (only applies to desktops and tablets)
.dropdown-question-item {
  .field {
    margin-bottom: 0 !important;
    display: inline-block; }

  .text-input {
    border-bottom: 1px solid $input-border-color;
    margin-left: 10px;
    width: 100%;
    font-size: inherit; }

  .field-text-input {
    display: inline-block;
    width: 60%; }

  @media screen and (max-width: $mobile) {
    .field-text-input {
      width: 95%; } } }

.dropdown-question-items {
  margin-bottom: 0px;
  display: block;

  &.is-highlighted {
    @include is-highlighted; } }


@media screen and (max-width: $mobile) {

  .is-shown-on-wide-screens {
    display: none !important; }

  .is-shown-on-small-screens {
    display: block !important; } }

// limit height of signature pad if screen is in landscape mode

// Android switches to landscape mode when the onscreen keyboard shows up.
// See this StackOverflow issue for further information.
@media screen and (min-width: $tablet) {
  canvas.signature-pad {
    max-height: 45vh; }

  .is-shown-on-wide-screens {
    display: flex !important; }

  .is-shown-on-small-screens {
    display: none !important; } }

// hide the header of the signature modal on phones in landscape mode
// this is a workaround because the damn Safari 13 shows the toolbar
// when the user focuses an input field
// but don't hides it after the input lost the focus
// it is also not possible to hide the toolbar by calling window.scrollTo
// the toolbar can only be hidden by a manual scroll event by the user
// Because in landscape mode we need the space stolen by the toolbar
// we hide the title bar of the modal winow
@media screen and (max-width: $mobile) and (orientation: landscape) {
  .signature-modal-header {
    display: none; } }

@media screen and (max-width: $mobile) {
  .result-section-heading-text {
    width: 100%;
    display: block;
    text-align: left !important; } }

@media screen and (min-width: $tablet) {
  .result-section-heading-text {
    width: 50%; } }

.overview-progress {
  width: 1rem !important;
  margin-right: 5px; }

.create-result-set-modal-card {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);

  z-index: $layer6;

  .react-tabs__tab-panel {
    overflow: auto; } }


//.modal-card-foot
//    justify-content: flex-end

.is-inverted {
  filter: invert(100%); }

.delete-signature {
  position: absolute;
  top: 5px;
  right: 5px;
  padding-left: 40px;
  padding-bottom: 40px; }

.signature-column {
  img {
    max-width: 20rem; } }

@media screen and (max-width: $tablet) {
  .audit-form {
    .panel-block {
      padding: 0; }

    .area-icons {
      font-size: 0.9em; } }
  .resel-question-detail-buttons {
    .control {
      flex-grow: 1; } } }

.result-checkmark {
  height: 22px;
  width: 22px;
  background-color: white;
  border: 1px solid #4a4a4a;
  border-radius: 3px;
  display: block !important; }

.result-checkmark-checked {
  width: 8px;
  border: solid #4a4a4a;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(38deg);
  transform: rotate(38deg);
  border-radius: 1px;
  //background-color: white
  position: absolute;
  top: 175px;
  left: 34px;
  height: 20px; }

.result-checkmark-na {
  position: absolute;
  left: 28px;
  top: 179px;
  font-size: 0.5em;
  font-weight: 900;
  color: $alphaprocess-blue;
  -webkit-user-select: none;
  user-select: none; }

.result-comments {
  transition: border 500ms;

  textarea {
    background-color: #f0f0f0;
    border-radius: 7px; }

  &.is-highlighted {
    border: 1px solid $danger; }

  .creator-and-time {
    font-size: 14px;

    .creator {
      color: black;
      font-size: 14px;
      margin-left: 16px; }

    .time-ago {
      margin-left: 2px;
      color: $mobile-light-grey; } }

  .delete-comment-icon {
    position: absolute;
    right: 0;
    top: 0; }

  div.comment-input {
    margin-bottom: 32px;

    textarea {
      min-height: 83px; } }

  .comment-content {
    position: relative;
    margin-bottom: 20px;
    margin-left: 16px;
    margin-top: 4px;

    font-size: 14px;

    .comment-text {
      max-width: 90%;
      display: inline-block;
      word-break: break-word;
      white-space: pre-wrap;
      color: $mobile-light-grey; } }

  .result-comment {
    position: relative;
    background: white;
    border: 4px solid $alphaprocess-light-blue;
    border-radius: 6px;
    padding: 10px; }


  &:after, &:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: white;
    border-width: 10px;
    margin-top: -10px; }

  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: $alphaprocess-light-blue;
    border-width: 16px;
    margin-top: -16px; } }


.result-question-unit {
  font-size: 1rem;
  position: absolute;
  bottom: 0px; }

.result-area-nav-buttons {
  padding: 10px;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);

  .button {
    flex-grow: 1;
    margin-right: 10px; } }


.checklist-overview, .home-desktop {
  &.has-fullscreen-margin {
    padding-left: 40px !important;
    padding-right: 40px !important; } }


.back-button {
  height: 48px;
  width: 156px;
  border-radius: 4px;
  font-size: 14px;
  color: rgba(0, 116, 228, 1) !important;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  background-color: #cce0f3;

  &:hover {
    color: rgba(0, 116, 228, 1) !important; } }

.continue-button {
  height: 48px;
  width: 156px;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  background-color: rgba(149, 155, 190, 1);

  &:hover {
    color: white; } }

// photo lightbox
.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: $layer7 !important;
    top: constant(safe-area-inset-top) !important;
    top: env(safe-area-inset-top) !important;

    .ril__image, .ril__imageNext, .ril__imagePrev, .ril__inner {
      top: constant(safe-area-inset-top) !important;
      top: env(safe-area-inset-top) !important; }

    *:focus {
      outline: none !important; }

    .lightbox-icons {
      > span {
        font-size: 1em; }
      .edit-photo-icon {
        margin-right: 20px; } }
    .delete-photo-button {
      top: 2px;
      left: 20px;
      position: absolute;

      .icon {
        height: 1em;
        width: 1em; } } } }

.media-content {
    .tasksTitle {
        padding: 5px 0px 10px 0px;
        color: $velian-gray;
        font-size: 16px;
        font-weight: 700; }
    .tasks-list {
        border: 1px solid $separator-color;
        border-radius: 2px; }
    .label {
        font-size: 14px;
        font-weight: 400;
        padding-top: 5px;
        padding-left: 8px; }
    .media + .media {
        margin-top: 0px !important;
        margin-bottom: 0px !important; } }

.checklist-image {
  width: 400px;
  height: 300px;
  img {
    max-height: 100%; } }
