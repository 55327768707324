@import "../_variables.sass";

$min-menu-height: 800px;

// Reduce margins between menu entries on Retina displays
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .side-menu {
    img {
      width: 121px; } } }

// make side menu scrollable if the screen's height is not large enough to fit all items
@media screen and (max-height: $min-menu-height) {
  .side-menu {
    overflow-y: auto !important;
    .menu-items {
      .image-icon {
        svg {
          height: 18px; } }
      .root-menu-text {
        font-size: 14px !important; } } } }

.menu-items-bottom {
  margin-top: auto !important; }

.side-menu.is-open {
  min-width: $side-menu-width !important;
  width: $side-menu-width !important;

  #toggle-side-menu {
    left: calc(50% - 16px) !important; }

  .menu-items.is-bottom {

    .root-menu-item {
      box-shadow: none; } } }

.menu-items-wrapper {
  display: flex;
  flex-direction: column; }

.side-menu {
  overflow: hidden;
  word-break: keep-all;
  transition: min-width .5s;
  z-index: $layer2;
  //transition-timing-function: ease-in-out
  #toggle-side-menu {
    position: absolute;
    bottom: 8px;
    left: calc(50% - 22px);
    color: $side-menu-text-color;
    z-index: $modal-z; }

  @media screen and (max-width: $mobile) {
    display: none; }
  background-color: $side-menu-background;
  box-shadow: inset -1px 0 0 0 #D4D8E0;
  position: relative;
  width: 50px;
  min-width: 50px;
  min-height: 100vh !important;
  height: 100%;

  >.app-logo {
    margin-left: 5px; }
  .spacingBlock {
    display: block;
    height: 26px; }
  .app-logo {
    margin-top: 20px;
    img {
      max-height: 100px;
      width: auto;
      max-width: 80%;

      &.default-app-logo {
        width: 60%; } } }

  #show-support {
    .image-icon {
      position: relative;
      top: 4px; } }

  .image-icon {
    margin-right: 1em;
    width: auto;

    i.far, i.fa {
      margin-top: 7px;
      font-size: 24px;
      height: 24px;
      width: auto; }

    svg {
      height: 24px;
      width: auto;
      margin-top: 6px; } }

  .side-menu-bottom-graphic {
    position: absolute;
    bottom: 152px;
    z-index: $layer2;
    margin-left: calc(50% - (205px / 2));
    height: 136px;
    width: 205px; }

  // prevent image sliding under the menu entries on smaller screens
  .side-menu-bottom-graphic, .side-menu-bottom-rect {
    @media screen and (max-height: 950px) {
      display: none; } }

  .side-menu-bottom-rect {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 182px;
    width: 100%;
    background-color: #496398;
    z-index: $layer1; }

  .icon {
    margin-right: 16px; }

  @media screen and (min-height: $min-menu-height) {
    .menu-items.is-bottom {} }

  .menu-items {
    margin-top: 10px;
    margin-left: 5px;
    background-color: white;
    box-shadow: inset -1px 0 0 0 #D4D8E0;
    transition: margin-left .4s;

    .sb-avatar {
      margin-left: 5px;
      margin-bottom: 5px; }

    .is-active {
      border-right: 4px solid $alphaprocess-blue;
      color: $alphaprocess-blue !important;

      a {
        color: $alphaprocess-blue !important;

        svg path {
          fill: $alphaprocess-blue !important; } } }
    .is-disabled {
      color: $grey !important;
      cursor: default;
      a {
        color: $grey !important;
        cursor: default;

        svg path {
          fill: $grey !important; } } }

    .root-menu-item {
      color: $side-menu-text-color;
      display: block;
      position: relative;
      line-height: 10px !important;
      padding: 5px 10px !important;
      margin-bottom: 5px;
      background-color: white;
      font-weight: 600;
      font-size: $side-menu-font-size !important;
      box-shadow: inset -1px 0 0 0 #D4D8E0;

      .icon {
        font-size: 22px; }

      .root-menu-text {
        transition: opacity .4s, width .4s, height .4s;
        transition-timing-function: ease-in;
        margin-right: 16px;
        position: relative;
        top: -4.5px;
        opacity: 0;
        height: 0;
        width: 0;
        display: block;
        font-size: unset !important; }

      .root-menu-text.is-open {
        opacity: 1;
        height: unset;
        display: unset; }

      a {
        color: $side-menu-link-color;
        font-size: $side-menu-font-size;

        .image-icon svg path {
          fill: $side-menu-link-color; } }

      a:hover {
        color: $alphaprocess-blue !important;

        .image-icon svg path {
          fill: $alphaprocess-blue; } } } } }

.root-menu-item::hover {
  color: $alphaprocess-blue !important; }

