@import "../variables";

.ressource-card {
  min-height: 544px;
  position: relative;

  .card-image {
    &:hover {
      .icon {
        display: block; } }

    .icon {
      position: absolute;
      top: 5px;
      right: 0;
      z-index: 20;
      display: none; } }

  img {
    max-height: 244px; }

  .ressource-card-tags {
    margin-top: 5px;

    .tag:not(:last-child) {
      margin-right: 5px; } }

  .card-header-title {
    word-break: break-word;

    .has-text-right {
      min-width: 50%; } }

  .card-footer {
    position: absolute;
    bottom: 0;
    width: 100%; } }


.ressource-details-header, .panel-heading {
  .image {
    display: inline-block; } }

.ressource-details {
  .field {
    .icon {
        height:28px!important {}
        padding-right: 11px; } }

  .tag-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .tag {
      margin-right: 5px;
      margin-top: 5px; } }

  .accordion-content {
    padding: 1em .5em !important;
    .icon {
      width: 1.3rem!important; } }


  .ressource-schedule-accordion {
    margin-top: .75rem; }

  .panel-heading:first-child, .panel-tabs:first-child, .panel-block:first-child {
    border-top: none !important; }

  .fa-info-circle {
    margin-top: -10px; } }

#new-resource-panel, #edit-resource-panel {
  margin-top: constant(safe-area-inset-top) !important;
  margin-top: env(safe-area-inset-top) !important;
  margin-top: 0 !important; }

.resource-form {
  .ck-editor__editable_inline {
    min-width: unset;
    width: 100%; }
  .cloud-file-list {
    h3 {
      font-size: 1rem; } }
  .dropdown-select__value-container {
    padding: 0; }

  .buttons:last-child {
    margin-bottom: 0; } }

.resource-search-bar {
  .search-field-wrapper {
    width: 100%; }

  .apply-button-wrapper {
    .button {
      margin-top: 1px; } } }

#quickviewDefault {
  z-index: $modal-z; }

.quickview {
  z-index: $modal-z;

  .quickview-block {
    padding-bottom: 20px; }

  .notes-panel {
    .content {
      width: 100%;
      figure.media {
        margin-left: 0;
        margin-right: 0; } } } }


.resource-quickview {
  table {
    background-color: #FAFAFA;
    thead {
      font-weight: bold; }

    tbody {
      background-color: white; } }

  .contacts-panel {
    a {
      color: currentColor;
      text-decoration: underline; } } }
