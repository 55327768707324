@import "../variables";

.rule-modal {
    .modal-card {
        width: 800px;
        .modal-card-body {
            min-height: 80vh; }
        .content {
            ul {
                margin-top: 0; } } } }
.rule-entry {
    .field-label {
        margin-right: 0;
        flex-grow: 1;
        text-align: left; }
    .input {
        font-size: 1rem; } }

.rule-action-form {
    .duplicate_mode_input {
        float: unset !important;
        font-size: smaller;
        input {
            margin-right: 8px;
            float: unset !important; }
        &:last-child {
            margin-bottom: 8px; } }
    .show-multiple-areas {
        align-items: center;
        justify-content: space-between;
        label {
            margin: 0; }
        input {
            text-align: right; } }
    .control {
        width: 50%; }

    .create-task-form {
        .control {
            width: 100%;
            label {
                float: none; } } } }

.rule-button-field {
    margin: 20px 0;
    .button {
        margin-right: -50px; } }

.rule-set:not(:last-of-type) {
    border-bottom: 1px solid $grey-lighter;
    margin-top: 5px; }
