@import "../_variables.sass";

$sticky-buttons-height: calc(64px);
.template-editor {
  overflow-x: hidden;
  .field-body {
    .switch[type="checkbox"] + label {
      margin-top: 0 !important; } }
  .share-tab {
    padding-right: 12px;
    .input {
      font-size: 16px; } }

  .contacts-table {
    tbody {
      a {
        color: currentColor;
        text-decoration: underline; } } }

  .control:not(.ckeditor-control) {
    input, label:not(.todo-list__label) {
      float: right; } }

  .field-label {
    //width: 500px !important
    display: flex; }

  .tooltip {
    span {
      font-size: inherit !important; } }

  table {
    break-word: break-word; }
  .question-catalog {
    .AreaList > .smooth-dnd-container > .smooth-dnd-draggable-wrapper:last-child {
      margin-bottom: 36px; }
    .AreaList {
      background: white;
      input:hover, input:active, input:focus {
        border-bottom: none !important; } }
    .question-catalog-columns {
      height: calc(100vh - 26px - 85px - 20px); // what are these magic numbers?
      margin-bottom: 0 !important;
      margin-right: 10px;
      .area {
        height: 100%; } }

    .react-pencil input {
      border-bottom: none !important; }

    .help-notification {
      margin-top: 1rem;
      margin-bottom: 1rem;

      p {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0; } }

      .react-pencil {
        width: 95%; } }

    .dropdown-icons {
      font-size: 1em;

      span {
        font-size: 1em; } }

    .area {
      width: 100%;
      &.question-details {
        min-height: 80vh;
        background-color: white;
        box-shadow: $box-shadow-default;
        border-radius: $border-radius;
        padding: 12px;
        overflow-x: hidden;
        // Sticky scroll
        //position: sticky
        top: calc(10px + 3.25rem);

        .delete-icon {
          color: #333;
          font-size: 1.5em;
          margin-top: 5px;
          padding-right: 5px; } } }

    .dropdown-list {
      input {
        border-bottom: 1px solid #EEEEEE !important; }
      .is-correct-checkbox {
        padding-top: 5px; }
      .correct {
        input {
          border-color: $success; } }
      .wrong {
        input {
          border-color: $danger; } } }

    .Questions, .dropdown-list {
      .smooth-dnd-wrapper {
        padding-top: 5px; }

      .smooth-dnd-draggable-wrapper:first-child {
        padding-top: 5px; }

      .smooth-dnd-wrapper {
        padding-top: 5px; }

      .value-range {
        position: absolute;
        right: 2rem;
        display: flex; } }

    .dragHandle {
      color: #b5b5b5;
      margin-right: 12px;
      margin-top: 4px; } }

  .sticky-buttons {
    position: fixed;
    bottom: 0;
    background-color: $background-color;
    z-index: $layer1;
    padding-bottom: 10px;
    left: 260px; }

  .sticky-buttons-menu-closed {
    left: 80px !important; }

  .auto-save-toggle {
    font-size: unset;
    display: inline-block;
    margin-top: 6px;
    margin-right: 25px;
    position: relative;

    .has-auto-save-label {
      margin-left: 20px; }

    input {
      position: absolute;
      top: 5px;
      left: 0; }

    span {
      font-size: unset; } }

  #checklist-overview-form {
    .is-scrollable-content {
      margin-bottom: $sticky-buttons-height; }
    .sticky-buttons {
      position: fixed;
      bottom: 0;
      background-color: $background-color;
      z-index: $layer1;
      padding-bottom: 10px;
      left: 260px; } }
  .measure-details {
    .ck-editor__editable_inline {
      max-width: unset !important; } }
  .measure-assignment-dialog {
    .modal-card-body {
      min-height: 400px;
      .measure-assignment-recommended-course-of-action {
        overflow: hidden;
        max-width: 150ch;
        text-overflow: ellipsis;
        font-size: smaller;
        -webkit-line-clamp: 3; } } } }


.area-entry-padding {
  padding: 10px 10px 20px 10px;
  input {
    border: 1px solid $lighter-grey; } }
