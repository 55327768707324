@import '../_variables.sass';

$desktop-width: 629px;
$padding: 5px;
$calendar-background-color: rgba(255, 186, 10, 1);
$font-color: black;

.filters.fadeOut {
  min-height: 0; }

.filters {
  box-shadow: $box-shadow-default;
  background-color: white;
  overflow: auto;
  min-height: 300px;
  z-index: $layer7;
  padding: 0 $padding $padding;

  @media screen and (min-width: $tablet + 1px) {
    width: $desktop-width;
    position: absolute;
    margin-top: 10px;
    border-radius: $border-radius; }

  @media screen and (max-width: $mobile) {
    position: fixed;
    top: 0;
    top: constant(safe-area-inset-top);
    top: env(safe-area-inset-top);
    height: 100%;
    height: calc(100% - constant(safe-area-inset-top));
    height: calc(100% - env(safe-area-inset-top));
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    z-index: $layer7;
    width: 100%;
    right: 0;

    .top-menu-footer {
      margin-bottom: constant(safe-area-inset-bottom);
      margin-bottom: env(safe-area-inset-bottom); }

    // all filters must fit on the screen without a scrollbar
    // make margines between buttons conditional based on the screen height of the mobile
    .conditional-margin {
      margin-bottom: 0;
      @media screen and (min-height: 770px) {
        margin-bottom: 20px; }
      @media screen and (min-height: 800px) {
        margin-bottom: 20px; } } }


  .reset-filters {
    background-color: lighten($link, 50%);
    color: $link; }

  .filter-title {
    height: 32px;
    width: 100%;
    text-align: left;
    color: rgba(37, 37, 41, 1);
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;

    .close-filters {
      float: right;
      padding-top: 10px;
      padding-right: 10px;
      padding-left: 40px;
      margin-top: -10px;
      cursor: pointer;

      img {
        width: 16px;
        height: 16px; } } }

  @media screen and (max-width: $mobile) {
    .filter-body {
      //position: fixed
      bottom: 20px;
      //max-height: calc(100vh - #{$bottom-menu-height})
      //overflow: auto
      padding-bottom: 20px;
      .filter-date-picker {
        .MuiDialogActions-root {
          display: none; } } } }


  @media screen and (max-height: 735px) {
    .filter-body {
      padding-bottom: 115px; } } }
