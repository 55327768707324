$cardsTotal: 3;
$cardsWidth: calc(100% - 32px);
$cardsPositionOffset: 55vh * 0.06;
$cardsScaleOffset: 0.08;
$defaultTranslation: $cardsPositionOffset * $cardsTotal;
$defaultScale: 1 - ($cardsScaleOffset * $cardsTotal);
$fs-card-title: 1.125em;

.card {
  @include card();
  @include absolute(0);
  @include sizing(100% 100vh);
  @include flex-column-space-between();

  @include after() {
    @include sizing(21px 3px);
    @include absolute(right 0 bottom 11px left 0);

    margin: auto;
    border-radius: 100px;
    // background: rgba($c-black, 0.3); // No for now
  }
  background: $c-white;
  padding: 20px;
  display: flex;
  max-height: 400px;
  margin: auto;
  font-size: $fs-h2;
  font-weight: $fw-bold;
  color: $c-white;
  opacity: 0;
  transform: translateY($defaultTranslation) scale($defaultScale) translateX($defaultTranslation);
  transform-origin: 50%, 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  user-select: none;
  pointer-events: none;
  will-change: transform, opacity;
  touch-action: none;

  &.isCurrent {
    pointer-events: auto;
  }

  &.isAnimating {
    transition: transform 0.7s $ease-out-back;
  }
  .ellipsis-wrapper{
    max-width: 100%
  }
}



.cardTitle {
  margin: 0 0 15px;
  font-size: $fs-card-title;
  text-align: left;
  max-width: calc(100% - 50px);
}

.button{
  font-size: 14px;
  height: 48px;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .cardTitle {
    color: $c-black;
    margin: 10px 0;
    font-size: 20px;
  }

  .cardProgress {
    height: unset;
    width: 37px;

    svg {
      .CircularProgressbar-trail {
        stroke: #d6e9fa;
      }

      .CircularProgressbar-path {
        stroke: $c-blue;
      }
    }
  }
}

.taskProgress {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  .task {
    font-size: 14px;
    color: $c-grey;
    font-weight: 300;
  }
  .percent {
    font-size: 14px;
    color: $c-blue;
    font-weight: 500;
    width: 40px;
    text-align: center;
  }
}

.cardContent {
  width: 100%;
  .info {
    font-size: 14px;
    color: $c-grey;
    font-weight: 300;
    margin-top: 1.5rem;
    @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 3, $bgColor: white);
  }
  
}

.cardFooter {
  width: 100%;
  color: $c-black;
  font-weight: 300;
  font-size: 14px;
  .fa, .far {
    margin-right: 5px;
    font-size: 20px;
  }
  .item {
    margin: 16px 0;
    display: flex;
    align-items: center;
    .is-20 {
      width: 20%;
    }
    .is-80 {
      width: 80%;
    }
    .is-100{
      width: 100%;
    }
    .icon {
      width: 30px;
    }
  }
}

.btnReschedule {
  width: 90%;
  color: $c-white;
  font-size: 15px;
  background-color: #d6e9fa !important;
  border: 0;
  .icon {
    color: $c-blue;

    &:hover {
      color: #d0bbbb;
    }

    i {
      margin-right: 0;
    }
  }
  cursor: pointer;
}

.btnOpen {
  width: 100%;
  color: $c-white;
  font-size: 15px;
  background-color: $c-blue !important;
  border: 0;
  cursor: pointer;

  &:hover {
    color: #d0bbbb;
  }
}

.fa_orange {
  color: orange;
}

.fa_red {
  color: #e43430
}

@for $i from 1 through $cardsTotal {
  $index: $i - 1;
  $translation: $cardsPositionOffset * $index;
  $scale: 1 - ($cardsScaleOffset * $index);

  .card:nth-child(#{$i}) {
    z-index: ($cardsTotal * 10) - $index;
    opacity: 1;
    transform: translateY($translation) scale($scale);

    @if $i != 1 {
      background-image: none;

      @include after() {
        @include sizing(0 0);
      }
    }
  }
}
