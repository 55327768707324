@import '~bulma/sass/utilities/initial-variables.sass';
@import '~bulma/sass/utilities/derived-variables.sass';


$level: 0;
$level-margin: 5px;
.move-folder-dialog {
  min-width: 400px;
  max-height: 400px;
  .folder-tree {
    overflow-y: auto;
    max-height: 240px;
    .folder-dialog-entry {
      cursor: pointer;
      color: $black; }
    .folder-dialog-entry.is-selected {
      color: $link;
      border: none; }

    @while $level <= 5 {
      .level-#{$level} {
        margin-left: $level-margin * $level; }
      $level: $level + 1; } } }
