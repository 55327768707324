@import "bulma/sass/utilities/initial-variables";
@import "../_variables.sass";

.data-table {
  &.has-layout-fixed {
    table-layout: fixed; }
  .data-table-action-row {
    min-width: 48px; }

  span:not(.tag) {
    font-size: inherit; }

  .gear-menu {
    .icon-settings {
      width: 24px;
      margin-top: 2px; }

    .dropdown-menu {
      width: 320px; }

    .dropdown-item {
      color: $link;
      font-size: $dropdown-item-font-size;
      line-height: $dropdown-item-line-height;
      font-weight: $dropdown-item-font-weight;
      //margin-bottom: $dropdown-item-margin-bottom

      .icon {
        margin-right: $dropdown-item-icon-margin-right; } } }

  .no-data {
    color: #252529;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px; }

  a {
    font-size: 16px;
    font-weight: 600;

    &.is-disabled {
      color: $grey;
      cursor: not-allowed;
      pointer-events: none; } }

  .has-not-allowed-cursor {
    cursor: not-allowed; }

  font-size: 14px;
  font-weight: 500;
  color: #828894;

  thead {
    background-color: #eef0f5;
    font-size: 14px;
    line-height: 20px; }


  th {
    font-size: 14px; }

  th, td {
    padding: 0.5em 0.5em !important; }
  td {
    //white-space: nowrap
    text-overflow: ellipsis;
    vertical-align: middle; }

  td.text-row {
    .is-ellipsed {
      white-space: nowrap; } }

  td.bold-text-row {
    font-weight: 600;
    color: $black; }

  tr.is-selected {
    color: $black;
    background-color: $white;
    border-right: 6px solid $link; }

  tr {
    border-right: 6px solid transparent;
    height: 36px; }

  tr:last-child {
    td {
      border-bottom-width: 1px !important; } }

  .folder-tag {
    width: auto;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 10px;
    margin-top: 2px;
    padding-top: 1px; }

  .audit-tag {
    width: auto;
    margin-left: 4px;
    margin-right: 4px; }

  .progress {
    width: 40px;
    @media screen and (max-width: 1100px) {
      width: 30px; }
    @media screen and (max-width: 950px) {
      width: 18px; }
    @media screen and (max-width: 850px) {
      width: 10px; } }

  .is-autostart-tag {
    color: #AF7D00;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    border-radius: 12px;
    background-color: #FFF5DB;
    width: 135px; }

  .table-avatar-display {
    span {
      font-size: inherit !important; } } }

