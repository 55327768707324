@import "../_variables.sass";

.project-tiles {
    .card {
        height: 2.2cm;
        border: 1px dashed gray;
        border-radius: 15px;

        .icon {
            font-size: 1.4rem;
            position: absolute;
            top: calc(50% - 1.4rem);
            right: calc(50% - 1.4rem); } } }
