@import "../_variables.sass";

$bottom-menu-width: 65.07%;
$bottom-menu-item-with: 23px;

.bottom-menu {
  .beamerTrigger {
    margin-top: 0;
    margin-right: 0; }

  @media screen and (min-width: $tablet + 1px) {
      display: none; }

  user-select: none;
  z-index: $layer5;
  background: $background-color;
  background: linear-gradient(to top, $background-color 0%, $background-color 70%);
  width: 100%;
  height: 100px;
  color: $link;
  position: fixed;
  bottom: 0;
  padding-top: 15px;

  .bottom-menu-click-outside {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $layer2; }

  // icons are embedded as inline svg
  svg {
    margin-right: $dropdown-item-icon-margin-right;
    path {
      fill: $link; } }

  .icon {
    color: $link; }

  .menu-entry-text {
    position: relative;
    top: -5.5px; }

  .bottom-menu-items {
    position: relative;
    display: flex;
    width: $bottom-menu-width;
    margin-left: 20%;
    z-index: $layer3;

    .bottom-menu-item {
      width: $bottom-menu-item-with;
      margin-top: 20px;
      font-size: 21px;

      #show-support {
        svg {
          position: relative;
          top: 3px; } }

      .show-support {
        display: block;
        height: 55px; }

      .bottom-menu-open {
        padding: 0 10px 0;
        position: relative;
        .icon {
          position: relative;
          top: 0px;
          left: -40px; } } }

    // circle thingy in the middle
    .bottom-menu-app-logo {
      margin: 0 calc(90px - #{$bottom-menu-item-with});
      height: 60px;
      width: 60px;
      border-radius: 30px;
      background-color: $link;
      box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.12), 0 8px 12px -4px rgba(130, 136, 148, 0.24);
      padding: 18px;


      .app-logo-icon {
        width: 24px; }

      a {
        display: block;
        height: 80px;
        width: 90px;
        position: relative;
        left: -35px;
        top: -18px;

        padding-left: 35px;
        padding-top: 18px;
        display: block; } }

    .dropdown-menu {
      top: -400px;
      width: 216px;
      z-index: $layer4;
      right: -30px;
      padding-top: 0;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.3);

      .dropdown-content {
        background-color: white; }

      .dropdown-item {
        padding-left: 16px;
        font-size: $dropdown-item-font-size;
        line-height: $dropdown-item-line-height;
        font-weight: $dropdown-item-font-weight;
        margin-bottom: $dropdown-item-margin-bottom;
        min-height: 43px;

        .menu-item-with-fa-icon {
          .menu-entry-text {
            top: -2px; } }


        .icon {
          font-size: 22px;
          margin-right: 11px; } }

      .is-active {
        font-weight: bold; } }

    .dropdown-menu:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 90%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: white;
      border-bottom: 0;
      margin-left: -8px;
      margin-bottom: -10px; } } }
