.cloud-attachment-picker {
  .dropdown-trigger .button {
    font-weight: bold;

    // cloud icon
    .icon:first-child {
      margin-right: 10px; }

    .dropdown-item {
      padding: 0 !important;

      // there is no var vor default dropdown item padding in bulma
      // https://github.com/jgthms/bulma/blob/master/sass/components/dropdown.sass
      .column {
        padding: 0.375rem 1rem !important; } } } }

.cloud-file-list.two-columns {
  .attachments-container {
    .attachment-thumb, .image-column {
      width: 50% !important; } } }

.cloud-file-list {
  .attachments-container {
    margin-right: 2em;

    &.is-highlighted {
      @include is-highlighted; }

    .attachment-thumb, .image-column {
      position: relative;
      min-height: 100px;

      @extend .has-margin-top-5;

      figure.image {
        margin: auto;
        text-align: center;
        max-width: 100%;
        width: 100px;

        img {
          margin: auto;
          height: 100%;
          width: auto; } }

      .filename {
        text-align: center;
        font-size: 0.8em;
        word-break: break-word;
        padding: 0 0px; }

      .delete-icon {
        position: absolute;
        top: 0;
        right: 0;
        z-index: $layer1; } } }

  @media screen and (min-width: $desktop) {
    .delete-icon {
      font-size: 18px;
      opacity: 0;
      transition: opacity .25s ease-in-out; }

    .attachment-thumb:hover > .delete-icon,
    .image-column:hover > .delete-icon {
      opacity: 1;
      transition: opacity .25s ease-in-out; } }

  .attachment-thumb {
    a {
      display: block;
      text-align: center;
      text-decoration: none !important;
      padding-left: 12px;
      padding-right: 12px;

      .icon {
        font-size: 50px;
        width: unset;
        height: unset;
        display: block; } } } }

.cloud-attachment-picker {
  margin-bottom: 1em; }
.cloud-file-list {
  margin-bottom: 0.5em;
  h3 {
    margin-bottom: 0; } }

