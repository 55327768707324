@import "../variables";
.report {
  color: $velian-gray;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  .report-back-button {
    flex-grow: 1;
    margin-top: 5px; }

  .report-downloader {
    background-color: #0074E4;
    border-color: transparent;
    color: #fff; }
  .tooltip:hover::before {
    opacity: 0; }

  .report-dropdown-menu {
    .dropdown-item {
      color: $link; } }

  .report-image {
    width: 200px;
    max-height: 200px;
    height: auto; }

  .only-required {
    font-size: 0.6em;
    margin-left: 5px; }

  .result-comments {
    display: inline;

    .comment-input {
      display: none; } }

  .documents {
    ul li {
      margin-bottom: 5px;

      a {
        text-decoration: none !important;

        .icon {
          margin-right: 5px; } } } }

  .value {
    display: block;
    hyphens: auto; }

  .report-content {
    .icon {
      height: unset;
      width: unset;
      padding-left: 0; } }

  h1, h2, h3, h4, h5, h6 {
    color: $velian-gray; }

  .container {
    width: 93%;

    .columns.is-gapless {
      margin-bottom: 0 !important; } }

  .header {
    width: 93%;
    margin: auto;

    label {
      display: inline-block;
      font-weight: bold;
      color: $light-grey;
      min-height: 1rem; }

    .hr-straight {
      margin-bottom: 1rem; }

    .columns {
      margin: 0; }

    .title-column {
      position: relative; }

    @media screen and (min-width: $tablet) {
      .status {
        position: absolute;
        bottom: 0;
        right: 0; } } }

  label {
    .icon {
      padding-left: 0; } }

  .body {
    max-height: calc(80vh - 3.25rem);
    overflow-y: auto;

    .accordion-content {
      padding: 0 !important; }

    .question {
      .title-column {
        width: 100%; }

      .comment {
        color: $light-grey; } }

    .area-title {
      width: 90%;
      word-break: break-word; }

    .attachments {
      margin-top: 10px; }

    .panel-heading {
      .columns {
        width: 100%; } } }

  .signature {
    display: inline-block; }

  .signature-container {
    padding: 10px; } }

.has-light-grey-border {
  border: 1px solid $light-grey; }

.panel.is-active .panel-block.accordion-body {
  display: block; }


.panel .panel-block.accordion-body {
  display: none; }

.accordion {
  .accordion-header {
    justify-content: left !important; }

  .accordion-header.accordions {
    .accordion {
      .accordion-content {
        padding: 0 !important; } } }

  .accordion-header.dark-grey {
    background-color: $accordion-header-background-color !important; }

  .accordion-header.light-grey {
    background-color: $light-grey !important; }

  .has-padding {
    padding: 1em 1.25em; } }

.report-back-button {
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
  margin-bottom: 8px;
  margin-left: 3.5%; }
