
$contact-filters-desktop-height: 180px;
$contact-filters-mobile-height: 245px;

.new-contact-panel {
  margin-top: 0 !important; }

@media screen and (min-width: $tablet) {
  .contact-cards {
    overflow-y: auto;
    overflow-x: hidden;

    .mobile {
      height: calc(100vh - #{$contact-filters-mobile-height}); }

    .desktop {
      height: calc(100vh - #{$contact-filters-desktop-height}); }
    margin-top: 5px; } }

.contacts-index {
  .notes-tag {
    cursor: pointer; }
  // Shorten too long names with ...
  .card-content {
    height: 240px !important;
    padding-bottom: 10px;
    padding-top: 10px; }

  .card-header-title {
     @extend .is-ellipsed; } }

.contact-form {
  .ck-editor__editable_inline {
    min-width: unset;
    width: 100%; }

  .documents-field {
    margin-top: 0.75rem; }

  .cloud-file-list {
    h3 {
      font-size: 1.0rem; } }

  .cloud-attachment-picker {
    .dropdown-menu {
      top: -155px; } } }
