$c-white: #fff;
$c-black: #000;
$c-red-50: #73345e;
$c-red-25: #432958;
$c-red-75: #632958;
$c-navy: #0f0e3d;
$c-text: #8285a7;
$c-percent: #3e98c7;
$c-blue: #0174e4;
$c-grey: #878d98;
$primary-gradient-start: #d94e47;
$primary-gradient-end: #df1165;
