.taskCardWrapper {
  @import "bulma/sass/utilities/_all.sass";
  @import "bulma/sass/grid/columns.sass";
  @import "bulma/sass/elements/_all.sass";
  @import '~@fortawesome/fontawesome-free/css/all.css';
  @import '~react-circular-progressbar/dist/styles.css';
  @import "./mixins.scss";
  @import "./colors.scss";
  @import "./typography.scss";
  @import "./animations.scss";
  @import "./card.scss";
  @import "./cards.scss";
}
