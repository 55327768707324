@import "_variables.sass";
@import "bulma/bulma.sass";
@import "sass/_bulma_helpers.sass";
@import "sass/_home.sass";
@import "sass/_sidemenu.sass";
@import "sass/_topmenu.sass";
@import "sass/_bottomMenu.sass";
@import "sass/_bubble.sass";
@import "sass/_project.sass";
@import "sass/_users.sass";
@import "sass/_result.sass";
@import "sass/filters.sass";
@import "sass/_customImageEditorUI.sass";
@import "sass/_templates.sass";
@import "sass/_editor.sass";
@import "sass/_passwordStrengthMeter.sass";
@import "sass/_search.sass";
@import "sass/_report.sass";
@import "sass/_history.sass";
@import "sass/_ressources.sass";
@import "sass/_importTab.sass";
@import "sass/_rules.sass";
@import "sass/folder.sass";
@import "sass/_boards.sass";
@import "sass/_charts.sass";
@import "sass/teams.sass";
@import "fonts/_fontface.sass";
@import "sass/_tabs.sass";
@import "sass/_dataTable.sass";
@import "sass/_contacts.sass";
@import "sass/_loadspinner.sass";
@import "sass/_cloudAttachments.sass";
@import "react-datepicker/src/stylesheets/datepicker.scss";
@import "react-tabs/style/react-tabs.scss";
@import "bulma-accordion/dist/css/bulma-accordion.sass";
@import "bulma-switch/src/sass/index.sass";
@import "bulma-quickview/dist/css/bulma-quickview.sass";
@import "bulma-steps/dist/css/bulma-steps.sass";
@import "bulma-badge/dist/css/bulma-badge.sass";
@import "~bulma-modal-fx/dist/css/modal-fx.css";
@import "~@fortawesome/fontawesome-free/css/all.css";

$resource-filters-height: 170px;

.MuiInputBase-root {
  input:hover, input:active, input:focus {
    border-bottom: none !important; } }

#select-portal {
  font-size: 0.875rem; }


.has-text-strike-through {
  text-decoration: line-through; }

html {
  background-color: $background-color !important;
  width: 100%;
  margin: 0; }

// experimental dark mode by simple invert
.dark-mode {
  background-color: black;
  filter: invert(1) hue-rotate(180deg);
  transition: background-color 0.3s ease, filter 0.3s ease;

  // Exclude images, videos, and canvas elements
  img, video, canvas {
    filter: invert(1) hue-rotate(180deg) !important;
    mix-blend-mode: normal !important; }

  // Utility class to exclude other elements explicitly
  .no-invert {
    filter: invert(1) hue-rotate(180deg) !important;
    mix-blend-mode: normal !important; }

  @media screen and (min-width: $tablet) {
    overflow: hidden; }

  // this disables the "Scroll Bouncing" effect
  // unfortunately it is not implemented yet in Safari
  // and the javascript Polyfill available at npm isn't working
  // I hope Apple will implement this css property in the next Safari version
  //overscroll-behavior: none
  @media screen and (max-width: $tablet) {
    overflow-x: hidden; } }

// no blue background-color for elements if we touch them
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

body {
  background-color: $background-color;
  font-size: 1em !important;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  @media screen and (min-width: $tablet) {
    overflow: hidden; } }

// iOS calendar like opacity effect on touch buttons
a:active,
.bottom-menu-item .icon,
.has-pointer img,
.close-filters,
.datatable-action-buttons .icon,
button,
.has-pointer .icon,
.fa-trash-alt {
  opacity: 1;

  &:not(:active) {
    transition: opacity 0.5s ease-out; }

  &:active {
    opacity: 0.2;
    transition: opacity 0.5s ease-out; } }

h1, h2, h3, h4, h5, h6 {
  display: block;
  font-weight: bolder;
  padding: 0 !important; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.17em; }

h5 {
  font-size: 0.83em; }

h6 {
  font-size: 0.75em; }

label.is-required:after {
  margin-left: 2px;
  content: "*";
  color: $red;
  font-size: 1.3em;
  font-weight: bold; }

textarea.textarea {
  padding: 10px;
  font-size: 0.875rem;
  font-weight: normal;
  background-color: white;
  border: 1px solid #EEEEEE;
  border-radius: 0 !important;
  outline: none !important;
  -webkit-appearance: none;
  box-shadow: none !important;

  &:focus {
    border-color: $input-border-color; }

  &::placeholder {
    color: $light-grey; } }


.title-field {
  font-weight: bold !important; }

.resource-select {
  .control {
    font-weight: 400 !important; } }

input.input, select, .react-datepicker-wrapper input {
  padding: 10px;
  font-size: 0.875rem;
  font-weight: normal;
  background-color: white;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #EEEEEE;
  border-radius: 0 !important;
  outline: none !important;
  -webkit-appearance: none;
  box-shadow: none !important;

  &:focus {
    border-color: $input-border-color; }

  &::placeholder {
    color: $light-grey; } }

input.input:-internal-autofill-selected {
  background-color: rgb(232, 240, 254) !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important; }

.dropdown-select__control {
  font-size: 0.875rem;
  padding: 0;
  background-color: white !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #EEEEEE;
  border-radius: 0 !important;
  outline: none !important;
  -webkit-appearance: none;
  box-shadow: none !important;
  z-index: $layer5;

  &:focus {
    border-color: $input-border-color;
    z-index: $layer6; } }

.is-invalid {
  border: 1px solid $danger; }

.is-blue {
  color: $alphaprocess-blue; }

.has-bgcolor {
  background-color: $background-color;
  min-height: 100vh; }

.card-footer-button {
  color: $alphaprocess-blue !important;
  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    background-color: transparent !important; } }

// Fixes for ADF-228: Icons out of viewport on some image sizes
#lightboxBackdrop {
  // Fixes the issue that the trash bin icon has a wrong position on mobiles with some image sizes
  .icon {
    height: unset;
    padding-left: 0; }

  // Max content div width must be full viewport width minus the minimal padding
  // Sorry but the react-images component doesn't support a css string as width.
  // So I have to hack this.
  div[class^="content_"] {
    max-width: calc(100vw - 20px) !important; } }

@media screen and (max-width: $mobile) {
  .icon-position-mobile-audit {
    position: absolute;
    top: 6px;
    right: 5px; }

  .icon-position-mobile-report {
    position: absolute;
    top: 12px;
    right: 5px; } }

@media screen and (min-width: $tablet) {
  .icon-position-mobile-report {
    margin-left: 5px; } }

.react-tabs__tab--selected {
    &.offline-available {
        color: $alphaprocess-orange !important; } }

#app-title {
  font-size: 1.4em;
  padding-left: 2%; }

.avatar-image {
  img {
    border-radius: $radius-rounded; } }

.box {
  margin-bottom: 0.5rem !important; }

.card {
  border-radius: $radius-large;
  box-shadow: $box-shadow-default; }

.card-image img {
  border-radius: 3px; }

.accordions {
  box-shadow: $box-shadow-default;
  border-radius: $radius-large; }

.accordion-header {
  border: 1px solid rgb(219, 219, 219);
  border-top-style: none;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-repeat: initial; }

.accordions .accordion .accordion-header + .accordion-body {
  opacity: 1; }

.accordions .accordion .accordion-header + .accordion-body {
  overflow: hidden;

  .button.is-text {
    white-space: normal;
    text-align: left;
    height: auto; }

  a:not(.button):not(.tag).is-current {
    color: white; } }

.accordions .accordion.is-active .accordion-body {
  max-height: 10000em; }

.accordions .accordion.is-closed .accordion-body {
  max-height: 0; }

.react-select {
  div[class*="-menu"] {
    z-index: $layer7; } }

.user-template-card {
  h2 {
    word-wrap: break-word; } }

.pagination {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  position: sticky;
  //background-color: #f3f5f9
  bottom: 0;

  @media screen and (max-width: $mobile) {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0; } }

// height of the bottom menu
@media screen and (max-width: $tablet - 1px) {
  .pagination {
    bottom: 100px; } }

.is-link {
  color: $link;
  cursor: pointer; }

.todo-list-container {
  .modal.is-active {
    z-index: $layer1 !important; } }

.modal.is-active:not(.not-on-top) {
  z-index: $layer6 !important; }

.modal-content {
  z-index: $layer3;

  .help-title {
    padding: 0.5em 1em; } }

.is-next-to-button {
  vertical-align: sub; }

.is-centered {
  justify-content: center !important; }

.is-text-centered {
  justify-content: center !important; }

.is-text-right {
  justify-content: right; }
.contact-card {
  font-size: .9em;
  .card-content {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    .contact-card-details {
      flex-grow: 1; }
    .contact-card-tags {
      display: inline-flex;
      flex-wrap: wrap; }
    .tags {
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;

      &.tags-container {
        margin-top: 0.5rem; } }
    .tag {
      font-size: 10px;
      margin-bottom: 0;
      a {
        color: white; } } }

  .contact-card-detail {
    word-break: break-all; } }

#about-links {
  a {
    text-align: center; }
  @media screen and (min-width: $tablet) {
    a {
      font-size: 1rem; }
    a:after {
      content: "●";
      font-size: 0.4em;
      vertical-align: middle;
      margin-left: 2%;
      margin-right: 8px; }

    a:last-child:after {
      content: ""; } } }

.justify-content-start {
  justify-content: flex-start; }

select:required:invalid {
  color: gray; }

option {
  &[value=""][disabled] {
    display: none;
    color: black; } }

.has-no-border {
  border: 0 !important; }

.reset-pasword-modal {
  .is-fullwidth {
    width: 100%; }

  .is-halfwidth {
    width: 50%; } }

.fc-today-button {
  text-transform: none !important; }

.velian-modal {
  // For reasons the width of a control in a modal is wrong
  .control-fullwidth {
    width: 100%; }

  .control-halfwidth {
    width: 50%; }

  .control {
    text-align: left !important; } }

.is-halfwidth {
  width: 50%; }

#modal-new-project {
  #show-location {
    // Project locations are not required for the protoype
    // But will get added later
    display: none !important; } }

.hr-straight {
  border-bottom: 1px solid $border-color; }

.logo-upload-widget-image {
  border: dashed 1px $border-color;
  padding: 10px;
  border-radius: $border-radius;
  margin-left: 5px;
  margin-bottom: 5px; }

.hr-text {
  //background-color: white
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;

  &:before {
    content: "";
    //background: linear-gradient(to right, transparent, #818078, transparent)
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px; }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 0 0.5em;
    line-height: 1.5em;
    color: #818078;
    background-color: #fcfcfa; } }

.has-rem-1 {
  width: 1rem !important;
  height: 1rem !important; }

#show-description, #show-location {
  .icon {
    width: 1rem !important;
    margin-right: 0.5rem; } }

.panel {
  box-shadow: $box-shadow-default; }

.panel-heading, .panel-tabs, .panel-block {
  border: 0; }

.panel-heading.is-info {
  background-color: $info;
  color: white; }

.panel-heading {
  img {
    width: 24px;
    float: left;
    margin-right: 10px;

    border: 0 !important; } }

.invalid, .invalid-field {
  border-color: $red; }

.has-text-success {
  color: $success; }

.has-text-red {
  color: $red; }

.has-text-grey {
  color: $grey; }

.has-border-grey {
  border-color: $grey; }

// ADF-374: For our own checkbox widget,
// Powered by Font Awesome
.pretty-checkbox {
  font-size: 25px;
  position: relative;
  .icon {
    margin-right: 10px;
    position: relative;
    top: 4px;
    font-size: unset !important; }
  .pretty-checkbox-na {
    position: absolute;
    left: 4px;
    font-size: .38em;
    top: 8px; } }

.invite-team-box {
  > div {
    background-color: white; } }

.nav-area {
  z-index: $layer2;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.navbar-icon {
  font-size: 1.5rem; }

.navbar-brand {
  .icon {
    margin-top: 2px; } }

// User profile menu is left aligned
.navbar-dropdown.is-left {
  left: calc(0px - 200%) !important; }

#root {
  display: flex;
  @media screen and (max-width: $tablet - 1px) {
    flex-direction: column; }
  @media screen and (min-width: $tablet) {
    overflow: hidden;
    height: 100vh; } }

#root-container {
  flex-grow: 1;
  min-height: 100vh;
  background-color: $background-color;
  @media screen and (min-width: $tablet) {
    overflow: auto; } }

.outdated-browser-notification {
  margin: 10px;
  // to show \n line breaks
  white-space: pre-line;
  z-index: $layer6; }

.notification {
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bolder; }

.schedule-notification {
  margin-top: 3.5rem; }

.autostart-field {
  display: flex;
  margin-top: 60px;
  .autostart-switch {
    flex-grow: 4; } }

.avatar-box-mobile {
  margin: 0 0 0 12px;
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);

  .notification {
    padding: 0.5rem;
    margin-bottom: 0.5rem; }

  .user-avatars {
    max-height: 160px;
    margin: 0 0 0.5rem 0;
    overflow: auto; }

  @media screen and (min-height: 730px) {
    .user-avatars {
      max-height: 260px; } }

  @media screen and (min-height: 800px) {
    .user-avatars {
      max-height: 355px; } } }

.modal {
  z-index: $layer6 !important; }

[data-badge]::after {
  z-index: $layer2 !important; }

.user-profile-box {
  .is-centered {
    display: flex;
    justify-content: center;
    align-items: center; } }

.signup-box, .modal-box {
  &:not(.modal-box) {
    margin-top: 25% !important; }

  @media screen and (max-width: $mobile) {
    &.user-profile-box {
      padding-top: 0px !important;
      margin-top: 15px !important; } }

  @media screen and (min-width: $tablet) {
    &.user-profile-box {
      padding-top: 0px !important;
      margin-top: 0px !important; } }

  .panel-tabs {
    background-color: $background-color;
    color: $body-color; }

  .panel-block {
    background-color: white;
    color: $body-color;

    .columns {
      width: 100%; } }

  form {
    width: 100%; }

  .is-fullwidth {
    width: 100%; }

  .modal-tabpanel {
    padding: 0.5em 0.75em; } }

.is-size-8 {
  font-size: 0.6rem; }

.flex-20-percent {
  flex: 20%; }

.flex-80-percent {
  flex: 80%;
  @media screen and (max-width: $desktop) {
    flex: 70%; } }

.flex-100-percent {
  flex: 100%; }

.is-aligned-right {
  .field-label {
    flex-basis: auto;
    width: 70%; }

  .field-body {
    width: 30%; }

  &.is-aligned-right-small-field {
    position: relative;

    .field-label {
      width: 70%; }

    .field-body {
      width: 30%; }

    // because the toggle switch is a magic CSS hack
    // we can not use regular css text-alignment
    .control {
      position: absolute;
      right: 3.15rem;
      top: -3px; } }

  &.is-aligned-right-large-field {
    .field-label {
      width: 40%; } } }

.is-scrollable {
  overflow: auto; }

.is-scrollable-vertical {
  overflow-x: hidden;
  overflow-y: auto; }

.has-pointer {
  cursor: pointer; }

.has-not-allowed {
  cursor: not-allowed !important; }

.has-image-centered {
  margin-left: auto;
  margin-right: auto; }

.panel-tabs.is-left {
  justify-content: left;
  padding-left: 20px; }

.question-catalog {
  margin-top: 8px !important; }

.tab-content:not(.question-catalog) {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 3%; }

.has-position-relative {
  position: relative; }

.has-position-bottom-right {
  position: absolute;
  right: 0;
  bottom: 0; }

$margin: 0;
$margin-val: 5px;

@while $margin <= 50 {
  .has-margin-#{$margin} {
    margin: $margin-val + $margin; }

  .has-margin-top-#{$margin} {
    margin-top: $margin-val + $margin; }

  .has-margin-right-#{$margin} {
    margin-right: $margin-val + $margin !important; }

  .has-margin-bottom-#{$margin} {
    margin-bottom: ($margin-val + $margin) !important; }

  .has-margin-left-#{$margin} {
    margin-left: ($margin-val + $margin) !important; }

  $margin: $margin + 5; }

$padding: 0;
$padding-val: 5px;

@while $padding <= 20 {
  .has-padding-top-#{$padding} {
    padding-top: $padding-val + $padding !important; }

  .has-padding-right-#{$padding} {
    padding-right: $padding-val + $padding !important; }

  .has-padding-bottom-#{$padding} {
    padding-bottom: $padding-val + $padding !important; }

  $padding: $padding + 5; }

.notice-message {
  font-size: 0.8em; }

.is-grey-text {
  color: #969faa; }

.is-clickable {
  pointer-events: initial; }

.is-transparent {
  background-color: rgba(0, 0, 0, 0);
  border-color: $black; }

// No fucking margins Safari!!!
// This is redundant but margin: 0 !important isn't enough in Safari
.overview-columns, .userprofile-columns {
  margin: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  &:last-child {
    margin: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

#checklist-overview-form, .question-catalog, #schedule-tab {
  .extra-large-margin-bottom {
    margin-bottom: 80px; }

  .small-margin-bottom {
    margin-bottom: 9px; }

  .small-margin-top {
    margin-top: 9px; }

  .minimal-margin-bottom {
    margin-bottom: 5px !important; }

  .minimal-margin-top {
    margin-top: 5px; }

  .area-title {
    font-size: 1.2em; }

  .measure-area-title, .measure-title {
    margin-left: 16px; }

  .dragHandle {
    padding-top: 1px;
    padding-left: 5px;
    margin-right: 5px; } }

.AreaTitle {
    padding: 10px 0; }

.AreaEntry {
  .delete-icon {
    display: none; }

  .selected-area {
    display: block;
    width: 100%; }

  .AreaTitle {
    .measure-title {
      div {
        font-size: 1.2em; } }
    div {
      color: $alphaprocess-blue; }
    .icon {
      color: black; }
    .is-text-right {
      padding-right: 10px;
      display: flex; }
    .icon i {
      padding-left: 9px; } }

  .delete-icon {
    display: inline-block !important; }

  input {
    border-bottom: none;
    background-color: initial; } }

.measure-drop {
  top: auto !important;
  left: auto !important; }

.draggable-icon {
  padding: 0 5px;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  img {
    width: 20px;
    height: 20px; }

  svg {
    width: 20px;
    margin-top: 4px; } }

.AreaList {
  background-color: $background-color!important; }

.AreaEntry {
  padding: 15px 10px 15px 0;
  background-color: white;
  border: 1px solid $lighter-grey;
  border-radius: 2px;
  margin: 10px 0;

  &:hover {
    background-color: #e9ecef;
    transition: 0.2s ease !important; }
  .input-field {
    border: 1px solid $lighter-grey;
    border-radius: 2px;
    background-color: $background-color;
    padding: 3px;
    height: 29px; }
  .areaTitle {
    &:hover {
      background-color: #e9ecef;
      transition: 0.2s ease !important; } }
  .react-pencil {
    width: 100%;
    padding-right: 12px; } }

.Questions {
  .draggable-icon {
    padding: 0 5px;
    cursor: grab;
    cursor: move;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    svg {
      width: 20px;
      margin-top: 4px; } }

  .smooth-dnd-draggable-wrapper {
      padding: 0 !important;
      margin: 5px 10px; }

  .is-horizontal {
    margin-right: 0px !important; }

  .smooth-dnd-draggable-wrapper:hover {
    background-color: #e0e0e0;
    transition: 0.2s ease !important; }

  .drag-class {
    background-color: #e0e0e0;
    transition: 0.2s ease !important; }

  .measure-section {
    .react-pencil {
      padding-right: 0 !important; }
    .measure-title {
      flex-grow: 1; } }

  //.question-section, .is-selected
  //  background-color: $link !important
  //  .draggable-icon
  //    svg
  //      fill: white
  //  .icon
  //    color: white

  .question-section {
    padding: 15px 10px 15px 0;
    background-color: white;
    border: 1px solid $lighter-grey;
    border-radius: 2px;

    .input-field {
      border: 1px solid $lighter-grey;
      border-radius: 2px;
      background-color: $background-color;
      padding: 3px; }

    .react-pencil {
      padding-right: 15px; }

    &:hover {
      background-color: #e9ecef;
      transition: 0.2s ease !important; } } }

.question-drop {
  background-color: #e9ecef !important; }

.area-drop {
  background-color: #e9ecef !important; }

.AreaEntry {
  padding-bottom: 20px; }

.new-entry-input {
  font-weight: 500;
  padding-left: 20px; }

// Make the Datepicker fullwidth on mobiles
.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block !important; }

// Set z-index to make the datepicker to appear in front of other controls.
.react-datepicker-popper {
  z-index: $layer7; }

// fix for youtube videos in CKEditor
figure.media {
  display: block; }

.react-datepicker-wrapper input {
  max-width: 100%; }

// pencil component has an invisible border by default
// so it does not jump on hovering
.react-pencil {
  input {
    border: 1px solid transparent;
    width: 100% !important; } }

// from where the fuck comes pointer cursor on all input:focus?
// This is not even in our stylesheets
input:not([type='button']):not([type='submit']):not([type='datetime']):not([type='datetime-local']):not([type='checkbox']):not([type='radio']) {
  cursor: text !important; }

// Styles for ck-editor and it's content
.ck-editor__editable_inline {
  min-height: 200px;
  min-width: 400px; }

.ck-editor-wrapper {
  display: flex;
  flex-wrap: wrap; }

.ck-editor {
  @extend .content;
  margin-left: auto !important;
  // line breaks should be <br/> instead of <p>
  // CKEditor 4 offered the "enterMode" option
  // to configure how the editor input handles <Return>
  // but there is no equivalent configuration in CKEditor 5
  // Too remove the margin between lines we have to adjust the
  // margin of paragraphs
  p:not(:last-child) {
    margin-bottom: 0 !important; } }

.ck-editor__main {
  overflow: auto; }

ul.todo-list {
  list-style-type: none; }

// styles for WYSIWYG text fields (e.g. description)
.content {
  // no spaces between paragraphs
  // new paragraphs must look like a regular new line
  p:not(:last-child) {
    margin-bottom: 0 !important; }

  // don't scale up small images
  img {
    width: unset !important;
    max-width: 100%; } }

.OverviewWidget {
  .OverviewArea {
    margin-top: 5px; }

  .OverviewItem {
    border: 1px solid Silver;

    padding-top: 35px; } }

.QuestionTypeSelect {
  width: 100%;

  //Delete Icons must not be over the select items
  z-index: $layer2; }

// delete confirmation alert must be over the lightbox
// Sorry, but react-confirm-alert doesn't provides *.sass files and variables
.react-confirm-alert-overlay {
  z-index: $layer7 !important;

  .react-confirm-alert-body {
    max-width: calc(100vw - 20px); } }

.result-section-percentage-text {
  font-size: 0.8em; }

.result-section-heading-text {
  font-weight: bold; }

.split-button-trigger {
  border-bottom-left-radius:  0px;
  border-top-left-radius: 0px;
  border-left: 1px solid #fff!important; }

#result-column {
  .dropdown:not(.cloud-file-selector):not(.area-card-view-drowpdown) {
    .dropdown-menu {
      right: -30px;
      left: unset; } }

  .accordion {
    margin-bottom: 0.5rem !important; }

  .accordions {
    box-shadow: $box-shadow-default; }

  .result-section {
    width: 100%;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    margin-bottom: 1rem;

    .panel-heading {
      border: 0;
      border-radius: $border-radius; }

    .panel-block {
      padding: 0 15px 10px 15px;
      border: 0;

      .column {
        padding-top: 0; } } }

  .is-full-width {
    width: 100%; }

  .has-padding-top-1rem {
    padding-top: 1rem;
    bottom: 0; } }

.tab-less-header-divider {
  .small-margin-bottom {
    padding-top: 40px; } }

.profile {
  margin-top: 5px;

  .media-left {
    margin-top: 30px; } }

.people-index {
  .react-tabs__tab-panel--selected {
    padding-left: 12px; }
  .media-left {
    padding-top: 2px; } }

.modal-card-body.signature {
  padding: 10px !important; }

@media screen and (min-width: $tablet) {
  .signature-modal {
    width: 90vw;
    max-width: 100%; } }

.signature-pad {
  border: 1px solid lightgrey;
  width: 100%; }

.card.is-selected {
  border: 2px solid lightblue !important; }

.is-selected {
  border: 1px solid lightblue; }

.has-buttons-right {
  justify-content: flex-end;
  padding-right: 5px;

  span {
    display: inline-block; } }

.has-font-size-1em {
  font-size: 1em; }

.tile-widget {
  width: 5rem;
  margin-right: 5px;

  &:last-child {
    margin-right: 0; } }

canvas.signature-pad {
  height: 300px; }

@media screen and (max-width: $mobile) and (max-aspect-ratio: 13/9) {
  .signature-free-selection {
    canvas.signature-pad {
      height: calc(100vh - 350px); } }
  .signature-without-selection {
    canvas.signature-pad {
      height: calc(100vh - 260px); } } }

@media screen and (max-width: $desktop - 1px) and (min-aspect-ratio: 13/9) {
  .signature-free-selection {
    canvas.signature-pad {
      height: calc(100vh - 250px); } }
  .signature-without-selection {
    canvas.signature-pad {
      height: calc(100vh - 160px); } } }

.progress-finish-state {
  position: relative;
  top: initial !important;
  vertical-align: 0 !important;
  height: 5px;
  display: block !important;
  @media screen and (min-width: $tablet) {
    max-width: 30%;
    max-width: 100%;
    margin-left: 0;
    margin-top: 0.6em; } }

.progress {
  position: relative;
  top: 0.4rem;
  height: 5px !important; }

.is-fullwidth {
  width: 100%; }

.has-line-breaks {
  white-space: pre-line; }

.checklist-overview {
  @media screen and (min-width: $tablet) {
    margin-left: 0; } }

@media screen and (min-width: $tablet) {
  #checklist-overview-form {
    .field, .has-small-margin-left-on-mobile {
      margin-left: 18px; }

    .field-label:not(.ckeditor-label) {
      flex-grow: 15; }

    .input-editor {
      min-width: 400px;
      top: -10px; }

    .icon {
      min-width: 2em; }

    .control {
 }     // width: 100%

    .select {
      width: 100%; } } }

@media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
  .signature-card {
    width: calc(100vw - 10%); } }

//@media screen and (min-width: $desktop)
//    .modal-card
//        margin: 0 20px

#checklist-overview-form {
  .is-scrollable-content {
    overflow-x: hidden; } }

#Embed button {
  border-radius: unset !important; }

.has-ellipsis {
  text-overflow: ellipsis; }

.ellipsis-wrapper {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.button-block {
  display: flex;
  justify-content: space-between;
  padding-top: 10px; }

.container.is-vertical-center {
  margin-top: 5%; }

.has-no-padding-right {
  padding-right: 0 !important; }

.subtitle.is-6 {
  color: $light-grey;
  font-size: 0.8em;
  margin-bottom: 0.5em; }

.has-text-light-grey {
  color: $light-grey; }

.quickview {
  top: const(safe-area-inset-top);
  top: env(safe-area-inset-top); }
.quickview-body {
  padding: 0.5em; }

// for some odd reason impulse based scrolling on iOS causes the z-index of task board cards to get ignored
// while dragging a card between the rows
// I tried the solutions provided at the following StackOverflow but none worked
// https://stackoverflow.com/questions/37696746/ios-not-respecting-z-index-with-webkit-overflow-scrolling
// So I've chosen to disable impulse based scrolling at the tasks page
html.disable-touch-scrolling:not(.is-old-safari) {
  -webkit-overflow-scrolling: auto !important;

  * {
    -webkit-overflow-scrolling: auto !important; } }

// we like touch smooth scrolling so we want it everywhere!!
html:not(.disable-touch-scrolling):not(.is-old-safari) {
  -webkit-overflow-scrolling: touch !important;

  * {
    -webkit-overflow-scrolling: touch !important; } }

li[class^="react-tabs"] {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-style: solid;
  border-bottom-width: 1px; }

li[aria-selected=true] {
  border-bottom-color: $alphaprocess-blue;
  color: $alphaprocess-blue; }

.is-white {
  background-color: $white; }

.project-tag {
  max-width: 100px;
  @media screen and (min-width: $tablet) {
    max-width: 200px; }
  @media screen and (min-width: $desktop) {
    max-width: 300px; } }

.welcome-msg {
  padding-left: 0.25rem; }

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: $layer3; }

.background-grey {
  background-color: $background-color; }

.signup-box {
  min-width: 300px;
  .react-tabs__tab-list {
    display: flex !important; } }

.ressource-schedule-entry:before {
  display: inline-block;
  content: "";
  border-right: .05rem solid $grey;
  width: 4rem;
  transform: translateY(-1rem); }

.dropdown-select__option, .dropdown-select__menu-list, .dropdown-select__menu {
  z-index: $layer7 !important;

  .schedule-row {}
  text-align: left;

  @media only screen and (min-width: 500px) {
    .has-buttons-right {
      display: flex; } } }


input[type="date"]::before {
  margin-left: 2px;
  color: hsl(0, 0%, 50%);
  content: attr(placeholder); }

input[type="date"]:focus::before {
  content: "" !important; }

// toasts must be in front of the "Add Task" modal
.Toastify__toast-container {
  z-index: $layer7; }

@media screen and (max-width: $mobile) {
  .Toastify__toast-container {
    position: fixed;
    top: 0;
    top: constant(safe-area-inset-top);
    top: env(safe-area-inset-top); } }

.Toastify__toast--success {
  background: $green !important; }

.Toastify__toast--error {
  background: $red !important; }

@media screen and (min-width: $tablet) {
  .table {
    th:first-child, td:first-child {
      padding-left: 40px;
      max-width: 30px; } } }

div:not(.overview-tabs) {
  .react-tabs {
    > .react-tabs__tab-panel {
      padding-left: 12px; } } }

.overview-tabs {
  .react-tabs__tab-list {
    .react-tabs__tab:first-child {
      padding-left: 0.5em !important; } } }

.table-avatar {
  .sb-avatar {
    margin-right: 5px; } }

.overview-tabs {
  .folder-wrapper {
    .table {
      thead {
        input {
          margin-top: -5px; } }
      .checkbox {
        height: 30px;
        width: 30px;
        margin-left: -2px;
        margin-top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        input {
          height: 15px;
          width: 15px;
          margin-left: -5px;
          padding-left: 0px; } } } } }

@media screen and (max-width: $mobile) {
  .table-avatar-display {
    position: relative;
    top: 8px; }
  #react-tabs-3 {
    .table-avatar-display {
      top: 0; } } }

.people-index, .board-index, .resource-index, .contacts-index, .sync-offline-checklists, .chart-index {
  margin-left: 40px !important;
  margin-right: 40px !important;
  .infinite-scroll-component__outerdiv {
    width: 100%; }
  @media screen and (max-width: $mobile) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;
    .board-title {
      width: 100%; } }
  .hidden {
    display: block;
    height: 10px; }

  .card-content {
    height: 190px;
    overflow: hidden; }

  @media screen and (min-width: $tablet) {
    .resource-cards, .resource-detail-data {
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100vh - #{$resource-filters-height});
      margin-top: 5px; } } }

.resource-index {
   margin-left: 0px !important; }

.modal-form {
  padding-top: 0;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  margin-top: .75rem;

  > .columns {
    margin-left: 0;
    margin-right: 0; } }

.sync-offline-checklists {
  @media screen and (min-width: $tablet) {
    margin-top: $top-menu-height !important; }
  margin-top: 20px; }

.contacts-index {
  padding-bottom: 10px; }

.tag {
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  box-sizing: border-box;
  height: 24px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px; }

.tag.is-due-today {
  background-color: $due-today-color;
  color: #AF7D00; }

.due-date-and-progress {
  top: -5px;
  postion: relative; }

.offline-mode-action {
  color: $alphaprocess-orange !important; }

.Toastify__toast--error {
  background: $red !important; }

.data-table {
  th {
    text-align: left !important; } }

.error-boundary {
  margin: 40px;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top); }

.show-error-details {
  margin-top: 5px;

  &:hover {
    background: $red; } }

.error-details {
  margin-top: 5px;
  background: $red; }

.tooltip:hover:not(.is-loading)::after, .tooltip.is-tooltip-active:not(.is-loading)::after {
  content: unset !important; }

#main-tooltip {
  max-width: 50vw;
  z-index: 80 !important; }

.offline-indicator {
  @media screen and (min-width: $desktop) {
    .indicator-text {
      padding: 4px 10px 0px 0px; } } }

.offline-indicator {
  margin: 5px;
  display: flex;
  @media screen and (max-width: $mobile) {
    font-size: x-small;
    .button {
      margin-left: 4px; }
    .indicator-text {
      flex-grow: 1; } } }

.modal-card-foot {
  .button {
    margin-bottom: 5px;
    height: 40px; }
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: 19px; }

.add-child-ressource-modal {
    .modal-card-foot {
        padding-bottom: 7px; } }

.delete-button {
  background-color: $red !important;
  color: white !important;
  &:hover {
    background-color: $darker-red !important;
    color: white !important; } }
.card {
  .delete-button {
    .button {
      color: $red !important; } }
  .delete-button {
    background-color: white !important;
    &:hover {
      background-color: $red !important;
      .button {
          color: white !important; } } } }

.edit-button {
  &:hover {
    background-color: $alphaprocess-blue;
    a {
      color: white !important; } } }


.delete-lane {
  color: $red;
  &:hover {
    background-color: $red !important;
    color: white; } }

a:hover {
  color: $link; }

.modal-fx-fadeInScale {
  top: constant(safe-area-inset-top);
  top: env(safe-area-inset-top); }

.icon {
  i.disabled {
    color: $grey; } }

.dropdown-menu {
  z-index: $modal-z; }

.__react_component_tooltip {
  z-index: $layer7 !important; }

.input[disabled] {
  color: $black; }

.beamerTrigger {
  margin-top: 20px;
  margin-right: 5px;
 }  //display: none

.field-body {
  .control {
    padding-top: 7px; } }

.dropdown-menu {
  .dropdown-item {
    align-items: center;
    display: flex;
    .fa-copy {
      padding-left: 4px;
      padding-right: 20px; } }
  .dropdown-item.is-disabled {
    opacity: 0.5;
    cursor: default !important; }
  .dropdown-item.is-danger {
    color: $red !important; }
  .dropdown-item:hover {
    background-color: $link;
    transition: ease 0.1s;
    color: white !important;
    a {
      color: white !important; } } }

.load-spinner {
  left: 50%; }

.react-confirm-alert-button-group {
  display: flex;
  justify-content: center;
  button {
    float: left;
    color: $alphaprocess-blue !important;
    cursor: pointer;
    text-decoration: none !important;
    outline: none;
    background: white;
    display: inline-block;
    padding: 6px 18px;
    width: 200px;
    color: #eee;
    height: 50px;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 16px;
    transition: 0.1s ease-out;
    &:hover {
      background-color: $alphaprocess-blue !important;
      color: white !important; } } }

a.uppy-Dashboard-poweredBy {
  display: none !important; }

.pagination-blur {
  display: block;
  width: 100%;
  filter: blur(10px);
  position: absolute;
  height: 45px;
  background-color: #f3f5f9;
  border-bottom: 20px solid white; }

.pagination-previous {
  opacity: 1 !important; }

.error-details-toggle {
  float: right; }

.error-view {
  word-break: break-word; }

.with-icon {
  .icon {
    margin-left: 4px; } }


.react-tabs__tab--disabled {
  color: #959bbe73; }

.switch-offline-wrapper {
  padding: 4px; }

.split-button-trigger {
  border-radius: 0px 5px 5px 0px; }

.iframely-embed {
  pointer-events: all !important; }

.react-select-15-live-region {
  z-index: 9999999 !important; }

.iframely-responsive {
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%; }

.iframely-responsive>* {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 0; }

.template-editor {
  #checklist-overview-form {
    .field-body {
      display: flex;
      justify-content: flex-end; }
    .input-editor {
      height: 100%; }
    .ck-editor-wrapper {
      flex-grow: 5 !important;
      .ck-content {
        width: 100%; }
      .ck.ck-editor {
        width: 100%;
        height: 380px; }
      .ck-editor__main {
        height: 92%; }
      .ck-content {
        height: 100%; } } } }

.filepond--credits {
  display: none !important; }

.css-1s2u09g-control {
  border-style: none !important; }

.dropdown-select-container {
  border-bottom: 1px solid #eee; }

#split-button {
  .dropdown-item {
    cursor: pointer; } }

.todo-list__label {
  input {
    float: unset !important; } }

.react-tabs__tab--selected {
  &:focus {
    &:after {
      background: $link !important; } } }


#beamerOverlay {
  padding-top: env(safe-area-inset-top); }

.ck-balloon-panel {
  z-index: 99999 !important; }
