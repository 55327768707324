.history-field-title {
  font-size: 0.8em;
  font-weight: bold;

  &:after {
    content: ":"; } }

.history-field-label {
  flex-grow: 1; }

.history-list {
  .history-entry {
    .history-value {
      padding-right: 5px; } } }
