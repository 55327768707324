$family-sans-serif: "SF Pro Text", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;

$velian-gray: #575759;
$mobile-light-grey: #828894;
$separator-color: #D4D8E0;

$alphaprocess-blue: #0074E4;
$alphaprocess-light-blue: #7CB6EF;
$alphaprocess-black: #202020;
$alphaprocess-orange: #F4AA1E;

$background-color: #f3f5f9;

$base-font-size: 16px;

$border-radius: 3px;

// FIXME: Don't ever use this. Use Bulmas own Tablet variable
$tablet: 769px;

$tablet-horizontal: 1112px;

//iPad Pro has a display width of 1366px
$ipad-pro: 1366px;

// 100vh does not work correctly on iOS tablets in the browser
// the browser navigation bar is not calculated, thats why we use an offset
$browser-offset: 57px;

$darker-red: #d02f2c;
$google-red: #dd4b39;
$green: #3DCC79;
$blue: #0094a9;
$red: #e43430;
$light-grey: #b5b5b5;
$lighter-grey: #cfcccc;
$grey: #828894;
$due-today-color: #FFF5DB;
$yellow: rgb(254, 172, 58);

$warning: $yellow;
//wird auch im bericht verwendet

$side-menu-background: #FFFFFF;
$side-menu-text-color: #959BBE;
$side-menu-link-color: #959BBE;
$side-menu-font-size: 16px;
$side-menu-width: 228px;
$top-menu-height: 75px;

$bottom-menu-height: 189px;

$panel-heading-background-color: rgb(150, 159, 170);
$menu-border-color: rgb(193, 193, 194);

// bulma modal-z override
$modal-z: 65535;

$box-shadow-default: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
$box-shadow-max: 0 9px 50px -18px rgba(0, 0, 0, .2), 0 16px 7px -20px rgba(0, 0, 0, 1), 0 1.5px 0 rgba(0, 0, 0, .15);

$border-color: #aaa;

// bulma override
$panel-heading-background-color: #FAFAFA;
$panel-heading-color: $alphaprocess-black;
$panel-item-border: 1px solid $border-color;

$modal-content-width: 700px;
$modal-card-head-padding: 20px;
$modal-card-title-size: 1.3rem;

$pagination-current-background-color: #757575;
$pagination-current-color: white;
$pagination-current-border-color: #363636;

$accordion-background-color: white;
$accordion-header-background-color: #FAFAFA;
$accordion-header-color: $alphaprocess-black;

$box-background-color: white;
$box-shadow: $box-shadow-default;

$input-focus-color: $alphaprocess-light-blue;
$input-focus-border-color: $alphaprocess-blue;

$navbar-background-color: $background-color;

$link: $alphaprocess-blue;
$link-hover: $alphaprocess-light-blue;

$progress-value-background-color: $alphaprocess-blue;

$card-header-background-color: #FAFAFA;
$card-header-shadow: none;

$tooltip-max-width: 60rem;

$input-border-color: #4a90e2;

$table-background-color: $background-color;
$table-head-cell-color: #9A9DAF;

$audit-form-padding: 0 24px;

$mobile: calc(#{$tablet} - 1px);

$dropdown-item-font-size: 16px;
$dropdown-item-line-height: 24px;
$dropdown-item-font-weight: 500;
$dropdown-item-margin-bottom: 5px;
$dropdown-item-icon-margin-right: 0.7em;

$layer1: 1;
$layer2: 20;
$layer3: 30;
$layer4: 40;
$layer5: 50;
$layer6: 60;
$layer7: 70;
