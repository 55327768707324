.cards {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 8px;
}
.notification {
  top: 30px;
  width: 80%;
  margin: auto;
}
