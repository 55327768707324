@import "../variables";

$trello-card-background-color: rgb(245, 245, 245);
$task-archive-header-height: 60px;
.chart-index {
  .chart-title {
    margin-top: calc(0.75rem + 15px);
    margin-bottom: 10px !important; }

  .chart-box {
    box-sizing: border-box;
    height: 140px;
    margin: 5px;
    padding: 10px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 700;
    transition: background .1s;
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    color: #fff;
    overflow-wrap: break-word;
    text-decoration: none;
    background-color: $alphaprocess-blue;

    .mini-chart {
      display: flex;
      height: 100%;

      .mini-list {
        display: inline-block;
        box-sizing: border-box;
        width: 24px;
        height: 100%;
        margin-right: 6px;
        border-radius: 3px;
        background: hsla(0, 0%, 100%, .25); } } } }

.chart-editor {
  margin-top: 26px;
  margin-left: 40px;
  margin-right: 40px;

  .io-zoom-controls {
    display: none;
    width: auto;
    position: fixed;
    right: 10px;
    bottom: 80px; }

  .bjs-powered-by {
    display: none; }

  .bpmn-container {
    height: calc(100vh - 190px);
    margin-bottom: 1.0rem; }

  #back-button {
    margin-bottom: 1.0rem;

    .icon {
      width: 1.0rem;
      height: 1.0rem;
      margin-right: 0.5rem; } }

  .title {
    margin-bottom: 1.0rem;
    margin-top: 1.0rem; } }

