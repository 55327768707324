.bubble {
  position: relative;
  width: 200px;
  color: white;
  height: 90px;
  padding: 10px;
  background: #2fa2e1;
  -webkit-border-radius: $border-radius;
  -moz-border-radius: $border-radius;
  border-radius: $border-radius;
  margin-top: 15px;
  margin-left: 10px;
  font-size: 0.8rem;
  justifiy-content: left;
  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 15px 15px 15px 0;
    border-color: transparent #2fa2e1;
    display: block;
    width: 0;
    z-index: $layer1;
    margin-top: -15px;
    left: -15px;
    top: 25%; }

  a {
      margin-top: 10px;
      display: block;
      font-weight: bold; } }
