.load-spinner {
  transform: translate3d(0,0,0);
  max-width: 150px;
  display: block;

  &.has-wave-with-margin-auto {
    margin: auto; }

  path {
    fill: #0074e4; } }


.centered {
  position: fixed;
  top: 50%;
  left: calc(50% - 75px);
  transform: translate(-50%, -50%);
  z-index: $layer7; }
