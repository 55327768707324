@import "../variables";
.user-profile {
  margin-top: 0;
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);

  .react-tabs__tab {
    @media screen and (max-width: $tablet) {
      padding: 6px 5px; } } }

.delete-user {
  position: absolute;
  top: 0;
  right: 0; }

.sb-avatar {
  position: relative; }
.sb-avatar.is-online {
  &::before {
    content: " ";
    display: block;
    width: 12px;
    border-radius: 6px;
    background-color: $green;
    height: 12px;
    position: absolute;
    top: -2px;
    right: -2px; } }

.sb-avatar.is-offline {
  &::before {
    content: " ";
    display: block;
    width: 12px;
    border-radius: 6px;
    background-color: $grey;
    height: 12px;
    position: absolute;
    top: -2px;
    right: -2px; } }

