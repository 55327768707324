$top-toolbar-height: 50px;
$bottom-toolbar-height: 80px;
$both-toolbar-heights: $top-toolbar-height + $bottom-toolbar-height;

.custom-image-editor-ui {
  z-index: $layer7;
  background-color: #282828;
  height: 100vh;
  height: calc(100vh - #{constant(safe-area-inset-top)});
  height: calc(100vh - #{env(safe-area-inset-top)});
  width: 100vw;
  position: fixed;
  top: 0;
  top: constant(safe-area-inset-top);
  top: env(safe-area-inset-top);
  left: 0;
  ul, li {
    list-style: none;
    margin: 0;
    padding: 0; }

  input[type=button], button {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff; }

  input[type=file] {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity = 0); }

  .header {
    position: fixed;
    left: 0;
    top: 0;
    top: constant(safe-area-inset-top);
    top: env(safe-area-inset-top);
    width: 100%;
    background-color: #fff;
    text-align: center;
    z-index: $layer7;

    .logo {
      margin: 10px 5px;
      width: 180px;
      vertical-align: middle; }

    .name {
      font-size: 16px;
      font-weight: bold; }

    .menu {
      padding: 10px;
      background-color: #000;

      input {
        opacity: 0; }

      img {
        width: 20px;
        height: 20px;
        vertical-align: middle; } }

    .button {
      position: relative;
      display: inline-block;
      margin: 0 5px;
      padding: 0;
      border-radius: 5px 5px;
      width: 30px;
      height: 30px;
      border: 0;
      background-color: #fff;
      vertical-align: middle;

      &.disabled i {
        opacity: 0.5;
        color: #363636; } } }

  .tui-image-editor-canvas-container {
    margin: auto; }

  .tui-image-editor-size-wrap {
    width: 100%;
    height: calc(100vh - #{$both-toolbar-heights});
    height: calc(100vh - #{$both-toolbar-heights} - #{constant(safe-area-inset-top)} - #{constant(safe-area-inset-bottom)});
    height: calc(100vh - #{$both-toolbar-heights} - #{env(safe-area-inset-top)} - #{env(safe-area-inset-bottom)});
    margin-top: 50px;
    display: table; }

  .tui-image-editor-align-wrap {
    display: table-cell;
    vertical-align: middle; }

  .tui-image-editor-controls {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #000;
    z-index: $layer7;

    .scrollable {
      display: inline-block;
      overflow-x: auto;
      width: 100%;
      height: 100%;
      margin-bottom: constant(safe-area-inset-bottom);
      margin-bottom: env(safe-area-inset-bottom);
      white-space: nowrap;
      font-size: 0;
      background-color: #000;
      vertical-align: middle; }

    .no-scrollable {
      overflow-x: hidden; }

    .menu-item {
      display: inline-block;
      height: 80px;
      border-right: 1px solid #383838;
      background-color: #ddd;
      vertical-align: middle; }

    .menu-button {
      width: 100px;
      height: 80px;
      border: none;
      vertical-align: middle;
      background-color: #000;
      color: #fff;
      font-size: 12px;
      outline: 0; }

    .submenu-button {
      width: 90px;
      height: 80px;
      border: none;
      background-color: #000;
      color: #fff;
      vertical-align: middle;
      white-space: pre-line;

      &.active {
        background-color: #fff;
        color:  #000; } }

    .hiddenmenu-button {
      margin: 0 10px;
      padding: 5px;
      border: none;
      color: #fff;
      background-color: rgba(255, 255, 255, 0); }

    .submenu {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      font-size: 0;

      &.show {
        display: block; }

      .menu-item:last-child {
        margin-right: 50px; } }

    .hiddenmenu {
      position: absolute;
      display: none;
      padding: 40px;
      width: 100%;
      left: 0;
      bottom: 80px;
      bottom: calc(80px + #{constant(safe-area-inset-bottom)});
      bottom: calc(80px + #{env(safe-area-inset-bottom)});
      background-color: rgba(0, 0, 0, 0.7);
      text-align: center;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      z-index: $layer7;

      .tui-colorpicker-palette-button {
        height: 32px!important;
        width: 32px!important;
        border: none; }

      .tui-colorpicker-clearfix {
        padding: 0 0 10px 0!important; }

      .tui-colorpicker-palette-toggle-slider {
        color: white;
        background-color: black!important;
        transition: 0.2s ease;
        border-radius: 1px; }

      .tui-colorpicker-palette-hex {
        color: white;
        background-color: black!important;
        border-bottom: 0.5px white solid;
        border-radius: 0px; }

      .tui-colorpicker-palette-preview {
        width: 24px;
        height: 24px; }

      &.show {
        display: block; }

      .top {
        font-size: 12px;
        color: #fff;
        margin-bottom: 20px;
        label {
          margin-right: 5px; }
        input[type=radio], input[type=checkbox] {
          margin-right: 3px;
          vertical-align: top;
          position: relative;
          top: 2px; } } }

    .btn-prev {
      display: inline-block;
      width: 30px;
      height: 80px;
      background-color: #000;
      color: #fff;
      border: none;
      border-right: 1px solid #383838;
      vertical-align: middle; }

    .tui-colorpicker-container {
      display: inline-block;

      li {
        padding: 0 3px 3px 0; }
      input {
        font-size: 0.8rem !important;
        background-color: white; } }

    .msg {
      position: absolute;
      margin-left: 50%;
      padding: 5px 10px;
      left: -86px;
      top: -50px;
      border-radius: 5px 5px;
      background-color: rgba(255, 255, 255, 0.5);
      font-size: 12px;

      &.hide {
        display: none; } } } }


