@import "../_variables.sass";

.avatars {
    position: absolute;
    bottom: 0; }

.zoom {
    transition: transform .2s; // Animation
    &:hover {
        transform: scale(1.03); } }

.team-role-select {
    display: inline-block;
    margin-left: 10px;
    min-width: 200px;
    max-width: 800px;
    width: calc(100% - 250px); }

.member-list-user-name {
    min-width: 200px;
    display: inline-block; }

#new_member {
    .dropdown-select__control {
        border-color: #dbdbdb;
        border-width: 1px; } }
#add-member {
    margin: -2px 0 1.4px;
    height: 40px;
    border-top: none;
    border-right: none; }

.modal.modal-fx-fadeInScale.is-active .modal-content {
    -webkit-transform: none;
    transform: none; }

.people-index {
    // add ... to too long team names
    .card-header-title {
        @extend .is-ellipsed; }

    .media + .media {
        margin-top: 0 !important; }

    .card {
        width: 480px;
        margin: 0 30px 30px 0;
        word-break: break-word;
        @media screen and (max-width: $tablet-horizontal) {
            width: 420px; }
        a {
            display: flex!important;
            justify-content: space-between!important;
            padding-right: 15px; } }

    .tab-single {
        float: left;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        article {
            //margin-bottom: 35px
            //min-width: 360px
            width: 100%;
            float: left;
            padding: 10px 15px 10px 10px !important;
            flex-basis: 0;
            margin: 0px 10px 10px 10px !important; } }

    .paragraph {
        width: 100%; }

    .teamTitle {
        width: 100%;
        padding-bottom: 15px !important;
        padding-top: 10px !important; }

    .user-property {
        width: 100%; } }
