@import "../_variables.sass";

.mobile-home {
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
  .has-max-width-90-percent {
    max-width: 90%; }
  .toggle-filter-button-container {
    padding-right: 12px !important;
    padding-top: 11px !important; }

  .has-text-centered {
    .columns {
      display: flex;
      min-width: 500px; } }
  .tag {
    color: white !important; }

  .filter-toggle-button {
    margin-left: 0;
    margin-right: 6px;
    margin-top: 6px;
    img {
      width: 21px; } }

  .artwork {
    margin-top: 32px;
    text-align: center;
    img {
      height: 252px;
      width: 303px;
      margin: auto; }

    .person {
      height: 220px;
      width: 297px; } }

  .caption {
    color: #828894;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 24px;
    margin-right: 4px;
    &-is-active {
      color: $black;
      opacity: 1; } }

  .second-caption {
    color: #252529;
    font-size: 20px;
    font-weight: bold;
    line-height: 32px; }

  .third-caption {
    color: $mobile-light-grey;
    font-size: 14px;
    line-height: 24px; }

  .toggle-filter {
    color: $alphaprocess-blue; }

  .tasks-todo-tag {
    border-radius: 14px;
    padding: 7px;
    margin-right: 8px;
    font-size: 12px; } }

.dot {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border: 2px solid #F4F4F4;
  border-radius: 8px;
  background-color: #FA6400;
  z-index: $layer2;
  position: absolute;
  right: -6px;
  top: -6px; }

.cardTitle {
  .is-ellipsed {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: break-spaces; } }

.new-project-button, .new-checklist-button {
  width: 169px;
  .icon {
    margin-right: 0 !important; } }

.new-project-item {
  color: #4a4a4a;
  display: flex;
  align-items: center; }
