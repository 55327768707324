@import "../_variables.sass";

.data-table {
  margin-bottom: 0 !important;
  thead {
    height: 36px;
    background-color: #E7E9EF;
    color: #9A9DAF;
    font-size: 14px;
    line-height: 20px;

    .checkbox {
      margin-top: 4px; } }

  .progress {
    top: .48rem !important;
    margin-left: 5px; }

  a {
    color: #252529;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    word-break: break-word; }

  tbody {
    tr {
      td.datatable-action-buttons {
        min-width: 80px; } }

    td:last-child {
      @media screen and (max-width: $tablet) {
        margin-right: 5px; } } } }

.data-table-action {
  margin-right: 5px; }

.data-table-footer {
  display: flex;
  padding-left: 4px;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  bottom: 0px;
  background-color: $background-color;
  height: 50px;
  padding: 5px;
  .data-table-pagination {
    flex-grow: 1;
    margin-right: 8px; }
  .data-table-multiselect-actions {
    .button:not(:last-child) {
      margin-right: 4px; } }
  @media screen and (max-width: $mobile) {
    bottom: 100px; } }
