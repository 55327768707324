// This file contains css rules which extends bulma
@media screen and (max-width: $mobile) {
    .columns.is-gapless-touch {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0; }

    .columns.is-gapless-touch:not(:last-child) {
        margin-bottom: 0.5rem; }

    .columns.is-gapless-touch:last-child {
        margin-bottom: 0; }

    .columns.is-gapless-touch > .column {
        margin: 0;
        padding: 0 !important; }

    .columns.is-gapless-touch > .column:not(:last-child) {
        margin-bottom: 0.5rem; }

    .columns.is-gapless-touch > .column:last-child {
        margin-bottom: 0; } }

.has-margin-auto {
    margin: auto; }

.has-margin-top-8 {
    margin-top: 8px; }

.has-margin-top-16 {
    margin-top: 16px !important; }

.has-margin-top-35 {
    margin-top: 35px; }

.has-margin-top-36 {
    margin-top: 36px; }


.has-margin-bottom-36 {
    margin-top: 36px; }


.has-margin-top-72 {
    margin-top: 72px; }

.has-margin-bottom-8 {
    margin-bottom: 8px !important; }

.has-margin-bottom-16 {
    margin-bottom: 16px; }

.has-margin-bottom-40 {
    margin-bottom: 40px; }

.modal-background {
    z-index: $layer3;
    width: 100vw;
    height: 100vh; }

.modal-card {
    z-index: $layer3; }

.has-position-bottom {
    position: absolute;
    right: 0;
    bottom: 0; }

.has-hyphens {
    hyphens: auto; }

.has-padding-right-4 {
    padding-right: 7px !important; }

.is-marginless-bottom {
    margin-bottom: 0 !important; }

.is-paddingless-top {
    padding-top: 0 !important; }

.is-paddingless-bottom {
    padding-bottom: 0 !important; }

.is-marginless-horizontal {
    margin-left: 0 !important;
    margin-right: 0 !important; }

.is-paddingless-vertical {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }

.is-paddingless-horizontal {
    padding-left: 0 !important;
    padding-right: 0 !important; }

.is-paddingless-right {
    padding-right: 0 !important; }

.is-ellipsed {
    hyphens: auto;
    word-break: break-word;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    display: block; }

.visual-separator {
    height: 1px;
    width: 100%;
    background-color: $separator-color; }

.is-disabled {
    opacity: .65;
    cursor: not-allowed;
    pointer-events: none; }

.has-no-text-decoration {
    text-decoration: none !important; }

.has-position-unset {
    position: unset !important; }

.notification.is-warning {
    color: #fafafa; }
