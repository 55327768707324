@import '../_variables';


.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 100%;
  position: fixed /* Stay in place */;
  z-index: $layer7 /* Sit on top */;
  left: 0;
  top: 0;
  background-color: rgb(255,255,255);
  background-color: rgba(255,255,255, 0.9);
  overflow-x: hidden /* Disable horizontal scroll */;
  transition: 0.5s /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */; }

