@import "../_variables.sass";

.top-menu {
  // place the buttons at the bottom of the filters box
  // if the fields doesn't fill the whole div box
  &.top-menu-small {
    .top-menu-footer {
      position: absolute;
      bottom: 5px;
      width: 98%; } }

  .dropdown-menu {
    right: 20px !important;
    top: 80%;
    z-index: $modal-z;

    .dropdown-item {
      padding: 0; }

    a {
      width: 100%;
      display: block;
      padding: 6px 16px; } }

  display: flex;
  width: 100%;
  padding-top: 0;
  padding-top: constant(safe-area-inset-top);
  /* iOS 11.0 */
  padding-top: env(safe-area-inset-top);
  /* iOS 11.2 */
  min-height: $top-menu-height;

  .top-menu-section {
    flex-grow: 1; }

  .search-fields {
    margin-top: 26px;
    width: 100%;
    margin-left: 10px;

    .filter-toggler {
      .filter-wrapper {
        height: 52px;
        width: 52px;
        background-color: #E7E9EF;
        padding: 14px;
        border-radius: 5px;
        @media screen and (max-width: $mobile) {
          margin-left: auto;
          margin-right: 5px; }

        img {
          height: 24px;
          width: 24px;
          font-size: 24px; } } }

    .search-term-column {
      @media screen and (min-width: $tablet) {
        width: 351px; }
      margin-right: 16px; }

    .search-term {
      width: 351px;
      height: 52px;
      background-color: #E7E9EF;
      font-size: 16px;
      line-height: 24px;
      color: $link;
      border-radius: $border-radius !important;

      ::placeholder {
        color: rgba(78, 83, 112, 1) !important;
        font-size: 16px;
        line-height: 24px; } }

    .control {
      .icon {
        width: 24px;
        font-size: 24px;
        margin-left: 10px;
        margin-right: 10px;
        //color: rgba(78, 83, 112, 1)
        color: rgba(78, 83, 112, 1) !important;
        opacity: 50%; } } }

  .dropdown {
    font-size: 14px;
    color: $alphaprocess-blue;
    margin-top: calc(#{$top-menu-height} / 2);
    margin-right: 32px;
    line-height: 20px;
    font-weight: bold;

    .dropdown-trigger.with-chevron:after {
      margin-left: 5px;
      margin-top: 1px;
      font-family: "Font Awesome 5 Free", serif;
      content: "\f078";
      font-weight: 900; } }

  .sb-avatar {
    margin: 24px; }

  .is-right {
    float: right; } }

@media screen and (max-width: $mobile) {
  .search-fields {
    width: 100%; }
  .search-term {
    width: 100% !important;

    .search-fields {
      .search-term-column {
        @media screen and (min-width: $tablet) {
          width: calc(100%) !important; } } } } }
