// Center icon in search button
.search-fields {
    .icon {
        padding-left: 0;
        top: -4px !important; }
    .search-term {
        width: 351px;
        height: 52px;
        background-color: #E7E9EF;
        font-size: 16px;
        line-height: 24px;
        color: $link;
        border-radius: $border-radius !important;

        ::placeholder {
            color: rgba(78, 83, 112, 1) !important;
            font-size: 16px;
            line-height: 24px; } }

    .control {
        .icon {
            width: 24px;
            font-size: 24px;
            margin-left: 10px;
            margin-right: 10px;
            //color: rgba(78, 83, 112, 1)
            color: rgba(78, 83, 112, 1) !important;
            opacity: 50%; } }
    @media only screen and (min-width: $tablet) {
        .no-margin-bottom {
            margin-bottom: unset; } }

    .no-margin-bottom-everywhere {
        margin-bottom: unset; }

    .filters {
        .field {
            min-width: 24.5%;
            font-size: 1rem;
            margin-right: 0.5%;
            &:last-child {
                margin-right: 0; } }
        .react-datepicker__input-container {
            input {
                height: 2.8em;
                padding-left: 0.5rem; } } }

    @media only screen and (min-width: $tablet) {
        .projects-filter {
            width: 49.5%; } }

    .filter-toggler {
        .icon {
            margin-top: 0.3em;
            padding-right: 1em; } } }
