@import "../variables";

$trello-card-background-color: rgb(245, 245, 245);
$task-archive-header-height: 60px;
.board-index {
  .control {
    font-size: 0.875rem; }
  .board-edit-card-body {
    min-height: 480px; }
  .board-title {
    margin-top: calc(0.75rem + 15px);
    margin-bottom: 10px !important; } }

.board-box {
  box-sizing: border-box;
  width: 260px;
  height: 140px;
  margin: 5px;
  padding: 10px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
  transition: background .1s;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  color: #fff;
  overflow-wrap: break-word;
  text-decoration: none;
  background-color: $alphaprocess-blue;

  .board-folder {
    position: relative;
    i {
      font-size: 100px;
      position: absolute;
      top: calc(50% - 35px);
      left: 50%;
      color: hsla(0,0%,100%,.25); } }


  .mini-board {
    display: flex;
    height: 100%;

    .mini-list {
      display: inline-block;
      box-sizing: border-box;
      width: 24px;
      height: 100%;
      margin-right: 6px;
      border-radius: 3px;
      background: hsla(0, 0%, 100%, .25); } } }

